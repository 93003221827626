export const english_translations = {
  value: 'GB',
  content: {
    Global_Meta_Description: 'Welcome to Lilenek, your marketplace where african companies (SMEs) & institutions commonly benefit from each others best goods and expertises, for the pleasure of consumers around the world.',
    Global_Status: 'status',
    Global_Button_View: 'View',
    Global_Button_Add: 'Add',
    Global_Button_Add_Cover: 'Add cover',
    Global_Button_Add_Logo: 'Add logo',
    Global_Button_Add_PDF: 'Add PDF',
    Global_Button_Add_IMG_File: 'Add IMG File',
    Global_Button_Resize: 'click on the picture to resize',
    Global_Button_Cancel: 'Cancel',
    Global_Button_Save_Updates: 'Save updates',
    Global_Button_Crop: 'Crop',
    Global_Button_Close: 'Close',
    Global_Button_Ratio: 'Image does not respect the desired aspect ratio',
    Global_Button_Delete: 'Delete',
    Global_Button_Reset: 'Reset',
    Global_Button_Share: 'Share',
    Global_Button_Save_Translation: 'Save Translation',
    Global_Button_Loading: 'loading...',
    Global_Button_Loading_Wait: 'loading, please wait...',
    Global_Button_Load_More: 'load more',
    Global_Button_See_More: 'see more',
    Global_Button_See: 'see',
    Global_Button_See_Less: 'see less',
    Global_Button_Follow: 'Follow',
    Global_Button_Unfollow: 'Unfollow',
    Global_Button_Next: 'Next',
    Global_Button_Back: 'Previous',
    Global_Button_Done: 'Done',
    Global_Address_Title_1: 'Address title',
    Global_Address_Title_2: 'HQ of, Office of, Address of, etc...',
    Global_Address_Street_Name_1: 'Street name',
    Global_Address_Street_Name_2: '83 peace road',
    Global_Address_Complement_1: 'Street name (complement)',
    Global_Address_Complement_2: 'Complement of street name',
    Global_Address_Complement_3: 'Apartment, suite, building, floor, etc...',
    Global_Address_Postcode_1: 'Postcode',
    Global_Address_Postcode_2: '75000',
    Global_Address_City_1: 'City',
    Global_Address_City_2: 'Peace City',
    Global_Address_County_1: 'County',
    Global_Address_County_2: 'County of peace',
    Global_Address_State_1: 'State',
    Global_Address_State_2: 'State of peace',
    Global_Address_Country: 'Country',
    Global_Address_GPS_1: 'Please locate the address above on the map.',
    Global_Address_GPS_2: 'Click on the marker to move it, and be as accurate as possible!',
    Global_Pin_SMS_1: 'Hello',
    Global_Pin_SMS_2: 'Your Lilenek verification code is:',
    Global_Activate_Email_Label_1: 'You must activate your email',
    Global_Activate_Email_Label_2: 'using the verification email we sent you.',
    Global_Activate_Email_Button_1: 'Sending email',
    Global_Activate_Email_Button_2: 'SENT',
    Global_Activate_Email_Button_3: 'RE-SEND',
    // APP COMPONENT
    App_User: 'User',
    App_Purchases: 'Purchases',
    App_Contacts: 'Messenger',
    App_Dashboard: 'Dashboard',
    App_New_Post: 'New post',
    App_Cart: 'Cart',
    App_Home: 'Home',
    App_Signup: 'Sign Up',
    App_Login: 'LogIn',
    App_Add_PWA_Content: 'Download Lilenek!',
    App_Add_PWA_Safari_Tap: 'Tap',
    App_Add_PWA_Safari_Content: 'and "Add to Home Screen"',
    App_Add_PWA: 'Download',
    // SWITCHLANGUAGEBUTTON COMPONENT
    SwitchLanguageButton_Button_Title: 'Languages',
    SwitchLanguageButton_Title: 'Select a language',
    SwitchLanguageButton_Current_Lang: 'Current language',
    // SEARCHENGINEMODAL COMPONENT
    SearchEngineModal_Title: 'Search',
    SearchEngineModal_Item: 'Article/Service',
    SearchEngineModal_User: 'User',
    SearchEngineModal_Company: 'Company',
    SearchEngineModal_Job: 'Job',
    SearchEngineModal_Opportunity: 'Opportunity',
    SearchEngineModal_Marketing: 'Marketing',
    SearchEngineModal_Filters: 'Filters',
    SearchEngineModal_Write_Keyword: 'Write a keyword...',
    SearchEngineModal_Looking_For: "I'm looking for...",
    SearchEngineModal_Looking_For_From_Country: 'in',
    SearchEngineModal_Write_Username: 'Write a username...',
    SearchEngineModal_Write_CompanyName: 'Write a company name...',
    SearchEngineModal_Filters_Country: 'Country',
    SearchEngineModal_Filters_Type: 'Type',
    SearchEngineModal_Filters_Industry: 'Industry',
    SearchItems_Not_Found: 'No item found',
    SearchItems_Not_Found_In: 'in',
    SearchUser_Not_Found: 'No user found',
    SearchUser_Follows_You: 'follows you',
    SearchCompanies_Not_Found: 'No company found',
    SearchCompanies_Subcategory: 'Subcategory',
    SearchCompanies_Category: 'Category',
    SearchJobMission_Not_Found: 'No jobs',
    SearchOpportuniy_Not_Found: 'No opportunities',
    SearchCompanies_Advanced_Search: 'Advanced search',
    // TOPBAR COMPONENT
    TopBar_Login: 'LogIn',
    TopBar_Signup: 'Sign up',
    // TOUR COMPONENT
    Company_Tour_1_1: "Go to 'Edit",
    Company_Tour_1_2: 'to add your products/services',
    Company_Tour_2: "then 'Create wallet' to receive/make payments",
    Profile_Tour_1: 'Options is where you can see most of the features you have as a user, from edit your profile to create a Company profile, post a business Opportunity, post a fun Flex and even post an object as Rental. And more to come!',
    Profile_Tour_2: 'Followers/Following is explicit enough, you can follow or be follow by other users',
    Profile_Tour_3: 'Companies This is the list of companies that you created, try to press this button',
    Profile_Tour_4: 'Deals is when 2 or more parties make a purchase of multiple products/services on specific terms, you can see it as an innovative online bargain',
    Profile_Tour_5: 'Connections is where your company is being follow by another companies',
    Profile_Tour_6: 'Experiences is a listing of your work experiences on Lilenek, it will be handy for future recruiters',
    // UPLOADIMGFEATURE COMPONENT
    UploadIMGFeature_Media_Type: 'picture',
    // FOOTER COMPONENT
    Footer_All_Rights: 'All rights reserved.',
    Footer_Legal_Mentions: 'Legal mentions',
    Footer_Legal_Mentions_Content: `          
    **1. Introduction:**
       - "Welcome to Lilenek, your gateway to the African Common Market, where business opportunities abound. At Lilenek, we are passionate about creating connections that empower SMEs, institutions, and entrepreneurs to thrive together. Our mission is to elevate Africa's reputation as a global provider of top-quality goods and expertise, uniting those who aim to make a difference. We invite you to join us on this exciting journey as we work together like never before."
    
    **2. Vision and Mission:**
       - Lilenek’s vision:
         - "We believe in an African Common Market where SMEs & institutions could commonly benefit from each other's best goods and expertise. Africa already provides the best goods to the world, together let’s take it to the next level and connect people who aim to make a difference. Together like never before."
       - Mission Statement: 
         - "Lilenek aims to create a secure and transparent ecosystem where African companies within the continent, African entrepreneurs from the diaspora, and international companies can connect and conduct business with confidence."
    
    **3. Problem Statement:**
       - "Recognizing the need for efficient B2B services and infrastructure to support the growth of the African Trade market, Lilenek is committed to addressing this challenge through its platform."
    
    **4. Company Information:**
       - "Lilenek, operated by Africantum, is a registered business with its principal office located in France. You can contact us at contact@thebhub.io."
    
    **5. Terms of Use:**
       - By using Lilenek's services, you agree to abide by the following Terms of Use, which constitute a legally binding agreement between you and Africantum:
    
       **5.1. Account Management and Business Growth:**
          - "Your Lilenek account is your key to unlocking opportunities in the African Common Market. We offer a comprehensive suite of tools and features to help you manage your business, connect with potential partners, and foster growth. By registering and using your account, you gain access to a world of possibilities."
    
       **5.2. User Conduct:**
          - Users must comply with all applicable laws and regulations.
          - Prohibited activities may include fraud, harassment, spam, and any illegal activities.
    
       **5.3. Content Submission:**
          - Users are responsible for the content they submit to the platform.
          - Prohibited content may include copyrighted material, hate speech, or harmful content.
    
       **5.4. Privacy and Data Use:**
          - Information about how user data is collected, stored, and used.
          - Consent for data processing and sharing within the platform.
    
       **5.5. Intellectual Property:**
          - Protection of intellectual property rights for both users and the platform.
          - Reporting mechanism for copyright or trademark infringements.
    
       **5.6. Transactions and Payments:**
          - Terms related to payment processing, including fees, refunds, and payment methods.
          - Seller responsibilities for accurate product descriptions and delivery.
    
       **5.7. Dispute Resolution:**
          - Procedures for dispute resolution, including mediation or arbitration.
          - Reporting and resolution of conflicts between users.
    
       **5.8. Termination and Suspension:**
          - Conditions under which accounts may be terminated or suspended.
          - Appeal process for users subject to account actions.
    
       **5.9. Liability and Warranty:**
          - Limitations of liability for the platform and its users.
          - Warranty disclaimers for products or services.
    
       **5.10. Governing Law and Jurisdiction:**
          - Choice of law and jurisdiction for legal matters.
    
       **5.11. Changes to Terms:**
          - The platform's right to update or modify the terms and conditions.
          - Notice to users about changes.
    
       **5.12. Indemnification:**
          - Users agree to indemnify and hold the platform harmless from legal claims or liabilities.
    
       **5.13. Communication and Notifications:**
          - How the platform communicates with users, including email notifications.
    
       **5.14. User Reviews and Feedback:**
          - Policies related to user reviews and feedback on the platform.
    
       **5.15. Security Measures:**
          - User responsibilities for maintaining account security.
          - Platform measures to protect user data.
    
       **5.16. Accessibility and Availability:**
          - Platform accessibility requirements and availability assurances.
    
       **5.17. User Termination Rights:**
          - Conditions under which users can terminate their accounts.
    
       **5.18. Community Guidelines:**
          - Platform-specific community guidelines and rules of conduct.
    
       **5.19. Compliance with Laws:**
          - User commitment to comply with all applicable laws.
    
       **5.20. Miscellaneous:**
          - Any additional legal clauses or disclaimers specific to the platform's mission and user base.
    
    **6. Privacy Policy:**
       - "Lilenek's Privacy Policy outlines how we collect, store, and handle user data in compliance with data protection laws. This includes the use of encryption to protect sensitive data and restricted access to authorized personnel only."
    
    **7. User Responsibilities:**
       - "As a user of Lilenek, you are responsible for adhering to ethical and legal standards in your interactions with other users and within the platform. This includes refraining from engaging in any fraudulent activities, scams, or misconduct."
    
    **8. Intellectual Property:**
       - "All intellectual property rights on Lilenek, including but not limited to trademarks and copyrights, are owned by Africantum. Users must respect these rights, and any reported violations will be addressed promptly."
    
    **9. Dispute Resolution:**
       - "In the event of disputes, users are encouraged to report issues through Lilenek's designated channels. Disputes may be resolved through mediation or arbitration, as outlined in our dispute resolution process."
    
    **10. Jurisdiction and Governing Law:**
        - "The jurisdiction for legal matters related to Lilenek is France. Any legal disputes will be governed by the laws of France."
    
    **11. Enforcement and Penalties:**
        - "Lilenek reserves the right to enforce these terms and policies. Violations may result in penalties, including account suspension or termination."
    
    **12. Reporting Misconduct:**
        - "If you encounter any misconduct, scams, or fraudulent activities on Lilenek, please report them immediately to our support team. We take user protection seriously and will investigate and address such issues promptly."
    
    **13. Legal References and Compliance:**
        - "Lilenek is committed to complying with all applicable national and international laws, including but not limited to consumer protection, e-commerce, and data protection regulations."
    
    **14. Data Protection and Privacy:**
        - "Lilenek values the privacy and data protection of its users. We are committed to complying with data protection laws, including the General Data Protection Regulation (GDPR) and any other applicable regulations."
        - "User Data Collection: We collect and process user data for specific purposes, including account management, communication, transaction processing, service improvement, smart market insights, security and fraud prevention, and personalization."
        - "Data Security: We employ robust security measures, including encryption, to protect user data from unauthorized access or breaches."
        - "Third-Party Sharing: User data may be shared with trusted third-party service providers for purposes such as payment processing and analytics. We ensure that these providers also comply with data protection regulations."
        - "User Rights: Users have the right to access, rectify, or delete their personal data held by Lilenek. Requests can be made through our designated channels."
        - "Cookies: We use cookies to enhance user experience. Users can manage cookie preferences through their browser settings."
        - "By using Lilenek's services, you consent to the collection, processing, and use of your data for the specified purposes as described in our Privacy Policy."
  `,
    // LOGINPAGE
    LoginPage_Hello: 'Hello',
    LoginPage_Friend: 'friend',
    LoginPage_Welcome_Back: 'Welcome back!',
    // LOGIN FORM COMPONENT
    LoginForm_Login_Title: 'Login',
    LoginForm_Identifier: 'Username / Email',
    LoginForm_Password: 'Password',
    LoginForm_Forgot_Password: 'Forgot password?',
    LoginForm_Turn_On_Location: 'Enable your location to fully secure your account',
    LoginForm_Login: 'Login',
    LoginForm_Logging: 'Logging...',
    LoginForm_No_Member_Yet: 'Not member yet?',
    LoginForm_Signup: 'Sign up now',
    LoginForm_Error_Login: 'You have the wrong ID/password, please try again or reset your password',
    LoginForm_Reset_Password: 'Reset password',
    LoginForm_Remember_Password: 'Do you remember your password?',
    LoginForm_Switch_User: 'Switch account',
    // FORGOTPASSWORDFORM COMPONENT
    ForgotPasswordForm_Identifier: 'Identifier',
    ForgotPasswordForm_Email_Mobile: 'Email/Mobile',
    ForgotPasswordForm_Pin_Verification: 'PIN Verification',
    ForgotPasswordForm_Please_Wait: 'please wait...',
    ForgotPasswordForm_Sending_SMS: 'Sending SMS...',
    ForgotPasswordForm_Send_Via_Email: 'Send PIN via Email',
    ForgotPasswordForm_Send_Via_SMS: 'Send PIN via SMS',
    ForgotPasswordForm_Validation: 'Validation',
    ForgotPasswordForm_Is_Submitted: 'Your password has been updated, please try to login!',
    ForgotPasswordForm_Login_Now: 'Login now',
    ForgotPasswordForm_Is_Updated: 'Your password has been updated!',
    ForgotPasswordForm_New_Password: 'New Password',
    ForgotPasswordForm_New_Password_Confirmation: 'New Password confirmation',
    ForgotPasswordForm_Reset_Password: 'Reset password',
    ForgotPasswordForm_Last_Step: 'Last step',
    ForgotPasswordForm_Login_Button: 'Login',
    ForgotPasswordForm_Back_Button: 'Back',
    ForgotPasswordForm_PIN_Verification_Button: 'PIN Code Verification',
    ForgotPasswordForm_Continue_Button: 'Continue',
    ForgotPasswordForm_Turn_On_Location: 'Enable your location to fully secure your account',
    ForgotPasswordForm_Is_Email_Submitted: 'Your email has been sent, please check your inbox',
    ForgotPasswordForm_Resend_Email: 'Something went wrong, please try again',
    // SIGNUPPAGE COMPONENT
    SignUpPage_Welcome_To: 'Welcome to',
    // SIGNUPFORM COMPONENT
    SignUpForm_Title: 'Sign Up',
    SignUpForm_Account_Info: 'Account info',
    SignUpForm_Username: 'Username',
    SignUpForm_Username_Taken: 'is taken already',
    SignUpForm_Email_Already_Used: 'is already used on another account',
    SignUpForm_Email_Incorrect: 'Email format incorrect',
    SignUpForm_Email: 'Email',
    SignUpForm_Gender: 'Gender',
    SignUpForm_DOB: 'Date of birth',
    SignUpForm_My_Gender: 'My pronoun is...',
    SignUpForm_Firstname: 'Firstname',
    SignUpForm_Lastname: 'Lastname',
    SignUpForm_New_Password: 'New Password',
    SignUpForm_Mobile_Verification: 'Mobile Verification',
    SignUpForm_Mobile: 'Mobile',
    SignUpForm_Sending_SMS: 'Sending SMS...',
    SignUpForm_Send_SMS: 'Send',
    SignUpForm_SMS_Error: 'SMS could not be sent to',
    SignUpForm_Beta_Notice: 'BETA VERSION: Here is your PIN CODE',
    SignUpForm_Use_International_Number: "Please use international format '+' or '00'",
    SignUpForm_Great: 'Great!',
    SignUpForm_Next_Step: 'Next step, will be the verification of your account',
    SignUpForm_We_Sent_Email: "After pressing on 'Create Account', we will send you an email to verify your account to",
    SignUpForm_To_Verify: 'to verify your account.',
    SignUpForm_Create_Account_Button: 'Create account',
    SignUpForm_Last_Step: 'Last step',
    SignUpForm_Login_Button: 'Login',
    SignUpForm_Back_Button: 'Back',
    SignUpForm_PIN_Verification_Button: 'PIN Verification',
    SignUpForm_Continue_Button: 'Continue',
    SignUpForm_Turn_On_Location: 'Enable your location to fully secure your account',
    SignUpForm_Already_Member: 'Already a member?',
    SignUpForm_All_Done: "All steps completed - you're all done",
    SignUpForm_Loading_message: 'Creation of your account, please wait...',
    // HOME COMPONENT
    Home_Title: 'Welcome to',
    Home_Hello: 'Hello',
    Home_Friend: 'friend',
    Home_Welcome: 'Welcome to',
    Home_On_Lilenek: 'Lilenek',
    Home_A_New_XP: '#ANewAfricanExperience',
    Home_Dashboard: 'Dashboard',
    Home_Latest_Companies: 'Latest business partners',
    Home_Latest_Job_Missions: 'Latest short term business opportunities',
    Home_Latest_Articles: 'Latest articles',
    Home_Latest_Services: 'Latest services',
    Home_Latest_Sponsorships: 'Sponsoring offers',
    Home_Latest_Discounts: 'Latest discounts',
    Home_Where_African_Companies: 'Your marketplace where African companies & institutions commonly benefit from each others best goods and expertises, for the pleasure of consumers around the world',
    Home_Explore_Now: 'Get Access for free',
    Home_Join_Thousands: 'Join thousands of African professionals, business partners and the latest entrepreneurial opportunities!',
    Home_Tour_1: 'Scan is where you can create your e-wallet and add payment methods to send/receive payments, manage your wallets, access financial services and see your latest transactions',
    Home_Tour_2: 'Search is how you search for anyone and anything on the African market, from products/services to companies or users, and even business opportunities',
    Home_Tour_3: 'Notifications is where you can see your latest received notifications',
    Home_Tour_4: 'Cart is where you add products/services to be purchase now or later',
    Home_Tour_5: 'Dashboard is where you can have an overview on all your activities and features that we offer you',
    Home_Tour_6: 'Feed is where you can see all the latest posts, business activities and opportinities',
    // PROFILE COMPONENT
    Profile_Updated: 'Successfully updated!',
    Profile_Help: 'Help/Guide',
    Profile_Admin: 'Admin',
    Profile_Feedback: 'Recommendation/report',
    Profile_Share_Profile: 'Share profile',
    Profile_Dashboard: 'Dashboard',
    Profile_Edit: 'Edit Profile',
    Profile_Settings: 'Settings',
    Profile_Share: 'Share profile',
    Profile_Followers: 'FOLLOWERS',
    Profile_Flexs: 'FLEXS',
    Profile_Followings: 'FOLLOWING',
    Profile_Companies: 'COMPANIES',
    Profile_Deals: 'DEALS',
    Profile_Connections: 'CONNECTIONS',
    Profile_Button_Following: 'Following...',
    Profile_Button_Follow: 'Follow',
    Profile_Button_Unfollowing: 'Unfollowing...',
    Profile_Button_Unfollow: 'Unfollow',
    Profile_Flex_Title: 'Flexs',
    Profile_Experiences_Title: 'Experiences',
    Profile_Opportunities_Title: 'Opportunities',
    Profile_Loading_Flexs: 'Loading flexs, please wait...',
    Profile_Time_To_Flex: "It's time to Flex",
    Profile_Loading_Experiences: 'Loading experiences, please wait...',
    Profile_Exp_For: 'for',
    Profile_Exp_At: 'at',
    Profile_Exp_Present: 'Present',
    Profile_Work_Experiences_Title: 'Work experiences',
    Profile_Entrepreneurship_Title: 'Entrepreneurship',
    Profile_Loading_Work_Experiences: 'Loading work experiences, please wait...',
    Profile_Loading_Entrepreneurship: 'Loading entrepreneurship experiences, please wait...',
    Profile_Take_Opportunity: 'Be the first to take this opportunity',
    Profile_Opportunity_1: 'persons',
    Profile_Opportunity_2: 'person',
    Profile_Opportunity_3: 'took this opportunity',
    Profile_Loading_Opportunities: 'Loading opportunities, please wait...',
    Profile_New_Opportunity: 'New opportunity',
    Profile_Send_Message: 'Send message',
    // SPENDINGCODEUPDATEMODAL COMPONENT
    SpendingCodeModal_Forgot_Spending_Code: 'Forgot spending code?',
    SpendingCodeModal_Email_Sent: 'Email sent!',
    SpendingCodeModal_Remember_Spending_Code: 'Do you remember your spending code?',
    SpendingCodeModal_Identifier: 'Identifier',
    SpendingCodeModal_Email_Mobile: 'Email/Mobile',
    SpendingCodeModal_Pin_Verification: 'PIN Verification',
    SpendingCodeModal_Please_Wait: 'please wait...',
    SpendingCodeModal_Sending_SMS: 'Sending SMS...',
    SpendingCodeModal_Send_Via_Email: 'Send PIN via Email',
    SpendingCodeModal_Send_Via_SMS: 'Send PIN via SMS',
    SpendingCodeModal_Validation: 'Validation',
    SpendingCodeModal_Is_Submitted: 'Your password has been updated, please try to login!',
    SpendingCodeModal_Is_Updated: 'Your spending password has been updated!',
    SpendingCodeModal_New_Password: 'New SpendingPassword',
    SpendingCodeModal_New_Password_Confirmation: 'New Spending Password confirmation',
    SpendingCodeModal_Reset_Password: 'Reset spending password',
    SpendingCodeModal_Last_Step: 'Last step',
    SpendingCodeModal_Login_Button: 'Login',
    SpendingCodeModal_Back_Button: 'Back',
    SpendingCodeModal_PIN_Verification_Button: 'PIN Code Verification',
    SpendingCodeModal_Continue_Button: 'Continue',
    // SPENDINGCODEUPDATEMODAL COMPONENT
    TipBox_First: 'Be the first to take it!',
    TipBox_Taken: 'taken by',
    // SENDRECOMMENDATIONMODAL COMPONENT
    SendRecommendationModal_Do_You_Want: 'Good friends share good tips',
    SendRecommendationModal_We_Add_Them: 'Tell your colleagues, business partners and friends about Lilenek, so they can also enjoy this new African experience.',
    SendRecommendationModal_Send_Button: 'Send',
    SendRecommendationModal_Email_Button: 'Email',
    SendRecommendationModal_Press_Enter: 'Press enter to add more',
    // DASHBOARD COMPONENT
    Dashboard_Notifications_Title: 'Notifications',
    Dashboard_My_Business_Title: 'Business activities',
    Dashboard_Merchandises_Title: 'Merchandises',
    Dashboard_Partnerships_Title: 'Partnerships',
    Dashboard_Investments_Title: 'Investments',
    Dashboard_Market_Analytics_Title: 'Market Analytics',
    Dashboard_Collaborations_Title: 'Collaborations',
    Dashboard_Business_Flexs_Title: 'Marketing',
    Dashboard_Last_Viewed_Title: 'Last Viewed',
    Dashboard_Favorites_Title: 'Favorites',
    Dashboard_Job_Missions_Title: 'Job Missions',
    Dashboard_New_Company: 'New Company',
    Dashboard_Ship_Package: 'Ship package',
    Dashboard_New_Discount: 'New discount',
    Dashboard_Overview_Title: 'Overview',
    Dashboard_Latest_Sells_Title: 'Latest sells',
    Dashboard_Calendar_Title: 'Services booked',
    Dashboard_Audio_Order_Title: 'Latest audio orders',
    Dashboard_Preview_Staffs_1: 'staffs',
    Dashboard_Preview_Staffs_2: 'Vacancies',
    Dashboard_Preview_Supplychains_1: 'Supplychains',
    Dashboard_Preview_Supplychains_2: 'Do you want to have a visibility on the suppliers that compose your supplychain?',
    Dashboard_Preview_Supplychains_3: 'Add your supplychain',
    Dashboard_Preview_Supplychains_4: 'HEALTHY',
    Dashboard_Preview_Supplychains_5: 'EXCELLENTE',
    Dashboard_Preview_Supplychains_6: 'WARNING',
    Dashboard_Preview_Supplychains_7: 'DANGER',
    Dashboard_Preview_Sells_1: 'latest sells',
    Dashboard_Preview_Sells_2: 'UNDER APPROVAL',
    Dashboard_Preview_Sells_3: 'NOT APPROVED',
    Dashboard_Preview_Sells_4: 'ON GOING',
    Dashboard_Preview_Sells_5: 'DELIVERY',
    Dashboard_Preview_Sells_6: 'DEAD DEAL',
    Dashboard_Preview_Sells_7: 'PAYMENT OVERDUED',
    Dashboard_Preview_Sells_8: 'CLOSED',
    Dashboard_Preview_Sells_9: 'UNDER APPROVAL',
    Dashboard_Preview_Sells_10: 'NOT APPROVED',
    Dashboard_Preview_Sells_11: 'ON GOING',
    Dashboard_Preview_Sells_12: 'DELIVERY',
    Dashboard_Preview_Sells_13: 'DEAD DEAL',
    Dashboard_Preview_Sells_14: 'PAYMENT OVERDUED',
    Dashboard_Preview_Sells_15: 'CLOSED',
    Dashboard_Preview_Job_Missions_1: 'Job missions',
    Dashboard_Preview_Job_Missions_2: 'Pending',
    Dashboard_ListStockManagement_1: 'Articles',
    Dashboard_ListStockManagement_2: 'Services',
    Dashboard_ListStockManagement_3: 'Tenders',
    Dashboard_ListStockManagement_4: 'Rentals',
    Dashboard_ListStockManagement_5: 'Stocks',
    Dashboard_ListStockManagement_6: 'Warehouses',
    Dashboard_ListStockManagement_7: 'Discounts',
    // COMPONENT LISTDISCOUNTS
    ListDiscounts_Add_Discounts_Button: 'New discount',
    ListDiscounts_No_Discount: 'No discount yet',
    ListDiscounts_Edit_Discount: 'Edit discount',
    // LISTPRODUCTS COMPONENT
    ListProducts_Add_Articles_Button: 'Add new article batch',
    ListProducts_No_Articles: 'No articles yet',
    ListProducts_See_Article: 'see article',
    // LISTSERVICES COMPONENT
    ListServices_Add_Services_Button: 'Add new service',
    ListServices_No_Services: 'No services yet',
    ListServices_See_Service: 'see service',
    // LISTTENDERS COMPONENT
    ListTenders_Add_Tenders_Button: 'Add new tender',
    ListTenders_No_Tenders: 'No tenders yet',
    ListTenders_See_Tender: 'see tender',
    // LISTRENTALS COMPONENT
    ListRentals_Add_Rentals_Button: 'Add new rental',
    ListRentals_No_Rentals: 'No rentals yet',
    ListRentals_See_Rental: 'see rental',
    // LISTSTOCKS COMPONENT
    ListStocks_Add_Stocks_Button: 'Create new stock',
    ListStocks_No_Stocks: 'No stocks yet',
    ListStocks_See_Stock: 'see stock',
    ListStocks_Services_Title: 'Services',
    ListStocks_Articles_Title: 'Articles',
    ListStocks_Products_Title: 'Products',
    ListStocks_Sells_Title: 'Last 7 days sells',
    // LISTWAREHOUSES COMPONENT
    ListWarehouses_Add_Warehouses_Button: 'Create new Warehouse',
    ListWarehouses_No_Warehouses: 'No Warehouses yet',
    ListWarehouses_See_Warehouse: 'see Warehouse',
    ListWarehouses_Services_Title: 'Services',
    ListWarehouses_Articles_Title: 'Articles',
    ListWarehouses_Products_Title: 'Products',
    ListWarehouses_Total_Stocks_Title: 'Total stocks',
    // PARTNERSHIP COMPONENT
    Partnership_Title_1: 'Why? How? What is a partnership?',
    Partnership_Title_2: 'Available Partnerships',
    Partnership_1: 'Fintech Partnership',
    Partnership_2: 'Fintech partnership for fintech company owners & financial institutions',
    Partnership_3: 'Retail Investor Partnership',
    Partnership_4: 'Retail Investor partnership for any company owners',
    Partnership_5: 'Logistics Partnership',
    Partnership_6: 'Logistics partnership for logistics & transportation owners',
    Partnership_7: 'Health Partnership',
    Partnership_8: 'Health partnership for health institutions and professionals',
    Partnership_9: 'Security Partnership',
    Partnership_10: 'Security partnership for security institutions and professionals',
    Partnership_11: 'Talent Resources Partnership',
    Partnership_12: 'Talent Resources partnership for HR institutions and professionals',
    Partnership_13: 'Risks Management Partnership',
    Partnership_14: 'Risks Management Partnership for risk management institutions and professionals',
    Partnership_15: 'Fivezer Partnership',
    Partnership_16: 'Fiverzer partnership for Five aside owners',
    Partnership_17: 'Auditor Partnership',
    Partnership_18: 'Auditor partnership for Audit institutions and professionals',
    Partnership_19: 'As a business owner, your ambition is to make your company scale-up and prosper; but expanding your market reach can be hard and time consuming. This is why we designed features that curated companies to partner with Lilenek and give unique opportunities to reach a larger market without efforts.',
    Partnership_20: 'To be eligible for a partnership, your business must:',
    Partnership_21: 'STEP #1',
    Partnership_22: 'be verified through our security and due diligence program (KYC), then we will notify you via email/notification about your eligibility.',
    Partnership_23: 'STEP #2',
    Partnership_24: 'Add one of the following subcategories into your business subcategories.',
    Partnership_25: 'Get started',
    Partnership_26: 'You must have at least one payment method on',
    Partnership_27: "'s",
    Partnership_28: 'wallet',
    Partnership_29: 'You must add the SUBCATEGORY on which you would like to start a partnership with',
    Partnership_30: '.',
    Partnership_31: 'If you already did, our team will evaluate your company and contact you soon.',
    Partnership_32: 'Commodity Compliance Partnership to help companies to export to other markets',
    Partnership_33: 'Commodity Compliance Partnership',
    // COMPANYCARD COMPONENT
    CompanyCard_Add_Company_1: 'Enjoy anything you want from the African market and more!',
    CompanyCard_Add_Company_2: 'Add your business',
    CompanyCard_Followers: 'Followers',
    CompanyCard_Followings: 'Followings',
    CompanyCard_Staffs: 'Staffs',
    CompanyCard_Total_Deals: 'Sells',
    CompanyCard_Flex_Items: 'Flex Items',
    CompanyCard_Supplychains: 'Supplychains',
    CompanyCard_Switch_Company: 'switch company',
    // NEWDEAL COMPONENT
    Deal_Legal_Mentions_Content: `    
    **Platform Usage Agreement**
    
    Please read the following Platform Usage Agreement carefully. By checking the box below, you acknowledge that you have read, understood, and agree to abide by the terms and conditions outlined in this agreement.
    
   
    **1. Purpose of the Platform**
    The Platform serves as a communication facilitator and marketplace connecting Buyers and Suppliers for various goods and services. The Platform provides a venue for the Parties to engage in business transactions, but it does not take responsibility for the quality, legality, or delivery of products or services exchanged between Buyers and Suppliers.
    
    
    **2. Responsibilities of the Platform**
    The Platform agrees to:
    
    2.1. Provide a platform for Buyers and Suppliers to interact and transact.
    
    2.2. Maintain the functionality of the platform and ensure that it is accessible to both Buyers and Suppliers.
    
   
    **3. Responsibilities of the Buyer and Supplier**
    3.1. The Buyer and Supplier acknowledge that they are solely responsible for their actions and transactions on the Platform.
    
    3.2. The Buyer is responsible for:
    
       a. The accuracy of any information provided on the Platform.
       
       b. Performing due diligence on Suppliers before entering into transactions.
       
       c. Payment for products or services in accordance with the terms agreed upon with the Supplier.
    
    3.3. The Supplier is responsible for:
    
       a. The accuracy of any information provided on the Platform.
       
       b. Fulfilling orders and delivering products or services as agreed upon with the Buyer.
       
       c. Resolving any disputes with the Buyer in a fair and timely manner.
    
    
    **4. Dispute Resolution**
    In the event of any disputes or disagreements between the Buyer and Supplier, the Parties agree to resolve such matters independently, without involving the Platform. The Platform shall not be held liable for any disputes or losses arising from transactions conducted on the Platform.
    
    
    **5. Limitation of Liability**
    The Platform shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to loss of profits, goodwill, data, or use, even if advised of the possibility of such damages, arising out of or in connection with the use of the Platform.
    
    
    **6. Indemnification**
    The Buyer and Supplier agree to indemnify and hold the Platform harmless from any claims, demands, or damages (including legal fees) arising out of their use of the Platform and any disputes or transactions with each other.
    
    
    **7. Termination**
    The Platform reserves the right to terminate or suspend a user's access to the platform at its discretion, with or without cause.
    
    
    **8. Changes to the Agreement** 
    The Platform may update this Agreement at any time. Users are encouraged to review this Agreement periodically.
    
    
    **9. Governing Law**
    This Agreement shall be governed by and construed in accordance with the laws of the Republic of Mauritius. Any disputes or claims arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of the Republic of Mauritius.
    
    By checking the box below, I hereby agree to the terms and conditions of the Platform Usage Agreement as outlined above
    `,
    // NEWPRODUCTMODAL COMPONENT
    NewProductModal_Title: 'New article batch',
    NewProductModal_As: 'as',
    NewProductModal_Name: 'Name',
    NewProductModal_Brand: 'Brand',
    NewProductModal_Category: 'Category',
    NewProductModal_SubCategories: 'Sub-categories',
    NewProductModal_SubCategories_Placeholder: 'Write a subcategory name',
    NewProductModal_SubCategories_Help: 'Press ENTER to add a custom subcategory',
    NewProductModal_Descriptions_Title: 'Descriptions (multi-languages)',
    NewProductModal_Descriptions_Label: 'Available languages (africans & internationals)',
    NewProductModal_Descriptions_Placeholder: 'Select a language',
    NewProductModal_Add_Language: 'translation',
    NewProductModal_Selected_Description_Label_1: 'Current descriptions',
    NewProductModal_Selected_Description_Label_2: 'and',
    NewProductModal_Selected_Description_Label_3: 'descriptions',
    NewProductModal_Selected_Description_Label_4: 'description',
    NewProductModal_Selected_Description_Label_5: 'with empty content',
    NewProductModal_Selected_Description_Placeholder: 'Select a description',
    NewProductModal_Selected_Description_Caption: '(empty content)',
    NewProductModal_Selected_Description_Content: 'Description in',
    NewProductModal_Selected_Description_Content_Warning: 'Dont forget to save your content after each updates',
    NewProductModal_Pictures_Title: 'Pictures',
    NewProductModal_Article_Composition_Title: 'Article composition',
    NewProductModal_Product_Name_1: "Article's name or",
    NewProductModal_Product_Name_2: 'product name',
    NewProductModal_Reference: "Article's reference/ID",
    NewProductModal_Article_Unit_Title: 'Article unit',
    NewProductModal_Product_Amount_Unit_1: 'Amount of',
    NewProductModal_Product_Amount_Unit_2: 'by',
    NewProductModal_Product_Unit_1: 'Unit',
    NewProductModal_Product_Unit_2: 'Select an article unit',
    NewProductModal_Size_Helper_1: 'Universal sizing, clothing & shoes sizes units',
    NewProductModal_Size_Helper_2: 'based on international sizing (source)',
    NewProductModal_Sizes_Title: 'Sizes',
    NewProductModal_Product_Measurements_Unit: "Article's measurements",
    NewProductModal_Product_Width: 'Width',
    NewProductModal_Product_Height: 'Height',
    NewProductModal_Product_Length: 'Length',
    NewProductModal_Weight_Title: 'Weight',
    NewProductModal_Weight: "Article's weight unit",
    NewProductModal_Weight_Unit_1: 'Weight of',
    NewProductModal_Weight_Unit_2: 'article',
    NewProductModal_Price_Title_1: 'Price (per',
    NewProductModal_Price_Title_2: 'article',
    NewProductModal_Value_1: 'Cost of',
    NewProductModal_Value_2: 'size',
    NewProductModal_Value_3: 'unit/',
    NewProductModal_Currency: 'Currency',
    NewProductModal_Amount_Stock_Title: 'Amount (in stock)',
    NewProductModal_Amount_Stock_1: 'Amount of',
    NewProductModal_Amount_Stock_2: 'article',
    NewProductModal_Button_Confirm_Article: 'Confirm article',
    NewProductModal_All_Articles_Title: 'All Articles',
    NewProductModal_No_Articles: 'No article(s) yet',
    NewProductModal_Storage_Title: 'Storage',
    NewProductModal_Storage_Subtitle_1: 'Add your',
    NewProductModal_Storage_Subtitle_2: 'articles',
    NewProductModal_Storage_Subtitle_3: 'article',
    NewProductModal_Storage_Subtitle_4: 'to which stock?',
    NewProductModal_Stock_In: 'Stock in',
    NewProductModal_Button_Create_Articles: 'Create articles',
    NewProductModal_Total_Worth: 'Total worth',
    // NEWSERVICEMODAL COMPONENT
    NewServiceModal_Title: 'New service',
    NewServiceModal_As: 'as',
    NewServiceModal_Name: 'Name',
    NewServiceModal_Brand: 'Brand',
    NewServiceModal_Category: 'Category',
    NewServiceModal_SubCategories: 'Sub-categories',
    NewServiceModal_SubCategories_Placeholder: 'Write a subcategory name',
    NewServiceModal_SubCategories_Help: 'Press ENTER to add a custom subcategory',
    NewServiceModal_Duration_Title: 'Duration (optional)',
    NewServiceModal_Duration: 'Duration',
    NewServiceModal_Duration_Unit: 'Time unit',
    NewServiceModal_Duration_Helper_1: 'Not every service can fix duration, but you can define an average duration for',
    NewServiceModal_Duration_Helper_2: 'your service',
    NewServiceModal_Price_Title: 'Price',
    NewServiceModal_Price_Value_1: 'Cost of',
    NewServiceModal_Price_Value_2: 'your service',
    NewServiceModal_Price_Currency: 'Currency',
    NewServiceModal_Descriptions_Title: 'Descriptions (multi-languages)',
    NewServiceModal_Descriptions_Label: 'Available languages (africans & internationals)',
    NewServiceModal_Descriptions_Placeholder: 'Select a language',
    NewServiceModal_Add_Language: 'translation',
    NewServiceModal_Selected_Description_Label_1: 'Current descriptions',
    NewServiceModal_Selected_Description_Label_2: 'and',
    NewServiceModal_Selected_Description_Label_3: 'descriptions',
    NewServiceModal_Selected_Description_Label_4: 'description',
    NewServiceModal_Selected_Description_Label_5: 'with empty content',
    NewServiceModal_Selected_Description_Placeholder: 'Select a description',
    NewServiceModal_Selected_Description_Caption: '(empty content)',
    NewServiceModal_Selected_Description_Content: 'Description in',
    NewServiceModal_Selected_Description_Content_Warning: 'Dont forget to save your content after each updates',
    NewServiceModal_Pictures_Title: 'Pictures',
    NewServiceModal_Locations_Title: 'Location(s)',
    NewServiceModal_Availablility: 'Available in',
    NewServiceModal_Availablility_Placeholder: 'Write a location name',
    NewServiceModal_Availablility_Helper: 'Location from where your service is deliver.',
    NewServiceModal_Button_Create_Service: 'Add Service',
    // NEWALERTMODAL COMPONENT
    NewAlertModal_Title: 'New alert',
    NewAlertModal_As: 'as',
    NewAlertModal_Name: 'Title',
    NewAlertModal_Status: 'Status',
    NewAlertModal_SubCategories: 'Sub-categories',
    NewAlertModal_SubCategories_Placeholder: 'Write a subcategory name',
    NewAlertModal_SubCategories_Help: 'Press ENTER to add a custom subcategory',
    NewAlertModal_Additional_Info: 'Additional Information',
    NewAlertModal_Level: 'Level',
    NewAlertModal_Accuracy: 'Accuracy',
    NewAlertModal_Radius: 'Radius in km',
    NewAlertModal_Descriptions_Title: 'Descriptions (multi-languages)',
    NewAlertModal_Descriptions_Label: 'Available languages (africans & internationals)',
    NewAlertModal_Descriptions_Placeholder: 'Select a language',
    NewAlertModal_Add_Language: 'translation',
    NewAlertModal_Selected_Description_Label_1: 'Current descriptions',
    NewAlertModal_Selected_Description_Label_2: 'and',
    NewAlertModal_Selected_Description_Label_3: 'descriptions',
    NewAlertModal_Selected_Description_Label_4: 'description',
    NewAlertModal_Selected_Description_Label_5: 'with empty content',
    NewAlertModal_Selected_Description_Placeholder: 'Select a description',
    NewAlertModal_Selected_Description_Caption: '(empty content)',
    NewAlertModal_Selected_Description_Content: 'Description in',
    NewAlertModal_Selected_Description_Content_Warning: 'Dont forget to save your content after each updates',
    NewAlertModal_Pictures_Title: 'Pictures',
    NewAlertModal_Button_Create_Alert: 'Post Alert',
    // NEWTENDERMODAL COMPONENT
    NewTenderModal_Title: 'New Tender',
    NewTenderModal_Introduction: `
    A tender gives you the opportunity to express ANY problems/needs that your organization has. 
    
    With this information, our system will provide you solutions in form of notifications based on the type of your need, at the best cost possible.
    
    It is important that you provide as much detail as possible in the descriptions below, which you can also translate in different laguages.
    `,
    NewTenderModal_Name: 'Title',
    NewTenderModal_Type: 'Type',
    NewTenderModal_Subcategories_1: 'Keywords specific to',
    NewTenderModal_Subcategories_2: 'your tender',
    NewTenderModal_Subcategories_3: 'Write keywords...',
    NewTenderModal_Subcategories_4: 'Press ENTER to add a custom subcategory',
    NewTenderModal_Start_Date: 'Start Date',
    NewTenderModal_End_Date: 'End Date',
    NewTenderModal_Descriptions_Title: 'Descriptions (multi-languages)',
    NewTenderModal_Cost_Title: 'Cost (approximately)',
    NewTenderModal_Cost_1: 'Cost of',
    NewTenderModal_Cost_2: 'your tender',
    NewTenderModal_Currency: 'Currency',
    NewTenderModal_Pictures_Title: 'Pictures',
    NewTenderModal_Documents_Title: 'Documents',
    NewTenderModal_References_Title: 'Search reference(s)',
    NewTenderModal_References_Subtitle: 'Attach reference(s) to your tender helps identify your need with tangible element(s).',
    NewTenderModal_References_1: 'Products or services',
    NewTenderModal_References_2: 'Write a reference name...',
    NewTenderModal_Selected_References_Title: 'Selected reference(s)',
    NewTenderModal_No_References: 'No reference(s) selected yet',
    NewTenderModal_Button_New_Tender: 'Submit Tender',
    NewTenderModal_Total_Worth: 'Total worth',
    // NEWRENTALMODAL
    NewRentalModal_Title: 'New Rental',
    NewRentalModal_As: 'as',
    NewRentalModal_Renter: 'Renter',
    NewRentalModal_Name: 'Name',
    NewRentalModal_Asset: 'Asset',
    NewRentalModal_New_Asset: 'New asset',
    NewRentalModal_Type: 'Type',
    NewRentalModal_Asset_Name: 'Name',
    NewRentalModal_Brand: 'Brand',
    NewRentalModal_Category: 'Category',
    NewRentalModal_Subategories_1: 'Sub-categories',
    NewRentalModal_Subategories_2: 'Press ENTER to add a custom subcategory',
    NewRentalModal_Subategories_3: 'Write a subcategory name',
    NewRentalModal_Product_Unit_Amount_1: 'Amount of',
    NewRentalModal_Product_Unit_Amount_2: 'by',
    NewRentalModal_Unit_1: 'Unit',
    NewRentalModal_Unit_2: 'Universal sizing, clothing & shoes sizes units',
    NewRentalModal_Unit_3: 'based on international sizing (source)',
    NewRentalModal_Sizes_Title: 'Sizes',
    NewRentalModal_Article_Measurements: "Article's measurements",
    NewRentalModal_Product_Width: 'Width',
    NewRentalModal_Product_Height: 'Height',
    NewRentalModal_Product_Length: 'Length',
    NewRentalModal_Weight_Title: 'Weight',
    NewRentalModal_Product_Article_Weight_Unit: "Article's weight unit",
    NewRentalModal_Product_Weight_1: 'Weight of',
    NewRentalModal_Product_Weight_2: 'article',
    NewRentalModal_Original_Price_Title: 'Original Price',
    NewRentalModal_Original_Price: 'Original price of',
    NewRentalModal_NewRentalModal_Original_Price_Currency: 'Currency',
    NewRentalModal_Warehouse_Second_Street_Name_1: 'Street address (complement)',
    NewRentalModal_Warehouse_Second_Street_Name_2: "Complement of warehouse's street address",
    NewRentalModal_Warehouse_Second_Street_Name_3: 'Apartment, suite, building, floor, etc...',
    NewRentalModal_Warehouse_Postcode_1: 'Postcode',
    NewRentalModal_Warehouse_Postcode_2: "Warehouse's postcode",
    NewRentalModal_Warehouse_City_1: 'City',
    NewRentalModal_Warehouse_City_2: "Warehouse's city",
    NewRentalModal_Warehouse_County_1: 'County',
    NewRentalModal_Warehouse_County_2: "Warehouse's county",
    NewRentalModal_Warehouse_State_1: 'State',
    NewRentalModal_Warehouse_State_2: "Warehouse's state",
    NewRentalModal_Warehouse_Country_1: 'Country',
    NewRentalModal_Duration_Title: 'Duration (optional)',
    NewRentalModal_Duration: 'Duration',
    NewRentalModal_Duration_Unit: 'Time unit',
    NewRentalModal_Duration_Info_1: 'What time based are you invoicing for',
    NewRentalModal_Duration_Info_2: 'your rental',
    NewRentalModal_Rental_Price_Title: 'Rental Price',
    NewRentalModal_Price_Currency: 'Currency',
    NewRentalModal_Descriptions_Title: 'Descriptions (multi-languages)',
    NewRentalModal_Pictures_Title: 'Pictures',
    NewRentalModal_Locations_Title: 'Location(s)',
    NewRentalModal_Pickup_Location: 'Pickup location',
    NewRentalModal_Dropping_Location_1: 'Dropping location(s)',
    NewRentalModal_Dropping_Location_2: 'Write a location name',
    NewRentalModal_Dropping_Location_3: 'Location from where your rental should be dropped.',
    NewRentalModal_Add_Rental: 'Add rental',
    // FORMNEWSTOCK COMPONENT
    FormNewStock_New_Stock_Title: 'New Stock',
    FormNewStock_Name: 'Name',
    FormNewStock_Owner: 'Stock owner',
    FormNewStock_Warehouse: 'Stock in warehouse',
    FormNewStock_Manager: 'Stock manager',
    FormNewStock_Create_Stock: 'FormNewStock_Create_Stock',
    FormNewStock_Create_Stock_Modal_1: 'New stock for',
    FormNewStock_Create_Stock_Modal_2: 'Are you satisfied with your new stock',
    FormNewStock_Create_Stock_Modal_3: 'and',
    FormNewStock_Create_Stock_Modal_4: 'as stock manager?',
    FormNewStock_Create_Stock_Modal_5: 'Yes, submit',
    FormNewStock_Create_Stock_Modal_6: 'No, go back',
    // FORMNEXCOMPANY COMPONENT
    FormNewCompany_Title: 'Create Your Company',
    FormNewCompany_Subtitle_1: 'Congratulation',
    FormNewCompany_Subtitle_2: 'on your initiative to take your business to the next level. The next steps are straightforward and editable later on.',
    FormNewCompany_Email_Not_Verifed_1: 'But before you start this journey with us, you must confirm your email address via the email we sent you at',
    FormNewCompany_Email_Not_Verifed_2: 'If you did, please reload this page.',
    FormNewCompany_Missing_Fields: 'You are missing a required field, please fill in all the required fields.',
    FormNewCompany_Step_1_Title: 'Basic Information',
    FormNewCompany_Step_1_Intro: 'Tell us about your company, we advice you to be as accurate as possible, transparency is a strong argument to attract more clients',
    FormNewCompany_Step_1_Name_1: 'Company Name',
    FormNewCompany_Step_1_Name_2: 'company_name',
    FormNewCompany_Step_1_Name_3: 'Name must have at least 4 characters',
    FormNewCompany_Step_1_Name_4: 'You should ONLY use letters, numbers or underscore',
    FormNewCompany_Step_1_Name_5: 'is taken already',
    FormNewCompany_Step_1_Label_1: 'Signboard',
    FormNewCompany_Step_1_Label_2: "Write your Company's label",
    FormNewCompany_Step_1_Label_3: 'Label must have at least 4 characters',
    FormNewCompany_Step_1_Label_4: 'There is an unauthorized character in',
    FormNewCompany_Step_1_Juridical_Status_1: 'Juridical status',
    FormNewCompany_Step_1_Juridical_Status_2: 'SAS, SARL, Limited, etc...',
    FormNewCompany_Step_1_Juridical_Status_3: 'Status must have at least 2 characters',
    FormNewCompany_Step_1_Juridical_Status_4: 'There is an unauthorized character in',
    FormNewCompany_Step_1_Category: 'Category',
    FormNewCompany_Step_1_Subcategory_1: 'Sub-categories',
    FormNewCompany_Step_1_Subcategory_2: 'Write a subcategory name',
    FormNewCompany_Step_1_Subcategory_3: 'Press ENTER to add a custom subcategory',
    FormNewCompany_Step_1_Email_1: 'Email',
    FormNewCompany_Step_1_Email_2: "Write your company's email",
    FormNewCompany_Step_1_Email_3: 'Your business email can not be the same as your personal email.',
    FormNewCompany_Step_1_Owner_Title: 'Business owner(s)',
    FormNewCompany_Step_1_Owner_Subtitle: 'Your business must have at least one owner',
    FormNewCompany_Step_1_Owner_1: 'Existing business Owner(s)',
    FormNewCompany_Step_1_Owner_2: 'Write a username',
    FormNewCompany_Button_New_Owner: 'Add non-existing owner',
    FormNewCompany_Button_Existing_Owner: 'Add existing owner',
    FormNewCompany_Button_Add_Owner: 'Add owner',
    FormNewCompany_Step_1_Registration_1: 'Business registration number',
    FormNewCompany_Step_1_Registration_2: 'Write your business registration number',
    FormNewCompany_Step_1_Registration_3: 'Certificate of incorporation',
    FormNewCompany_Step_1_Registration_4: 'max',
    FormNewCompany_Step_1_Registration_5: 'left',
    FormNewCompany_Step_1_Address_Title: 'Location',
    FormNewCompany_Step_1_Different_Address_Title: 'Is this address the same as where your products are stored?',
    FormNewCompany_Step_1_Different_Address_1: 'No, there are located at a different address.',
    FormNewCompany_Step_1_Different_Address_2: 'Yes, there are located at this address.',
    FormNewCompany_Step_1_Warehouse_Name_1: 'Warehouse name',
    FormNewCompany_Step_1_Warehouse_Name_2: 'Warehouse of ...',
    FormNewCompany_Step_2_Title: 'Details and Visuals',
    FormNewCompany_Step_2_Subtitle_1: 'Perfect!',
    FormNewCompany_Step_2_Subtitle_2: 'You introduce',
    FormNewCompany_Step_2_Subtitle_3: 'your company',
    FormNewCompany_Step_2_Subtitle_4: 'for us, now you can describe to your audience and future clients what you do and how great',
    FormNewCompany_Step_2_Subtitle_5: 'is!',
    FormNewCompany_Step_2_Slogan_1: 'Slogan',
    FormNewCompany_Step_2_Slogan_2: 'Write your slogan',
    FormNewCompany_Step_2_Slogan_3: 'characters',
    FormNewCompany_Step_2_Slogan_4: 'character',
    FormNewCompany_Step_2_Slogan_5: 'left',
    FormNewCompany_Step_2_Slogan_6: 'You must remove',
    FormNewCompany_Step_2_Descriptions_Title: 'Descriptions (multi-languages)',
    FormNewCompany_Step_3_Title: 'Staff Members',
    FormNewCompany_Step_3_Subitle_1: 'Great progress!',
    FormNewCompany_Step_3_Subitle_2: 'You can also manage and monitor many aspects of',
    FormNewCompany_Step_3_Subitle_3: 'your company',
    FormNewCompany_Step_3_Subitle_4: "'s team(s), and it will help you hire the right talents.",
    FormNewCompany_Step_3_Subitle_5: 'All you need to do is to send an invitation to',
    FormNewCompany_Step_3_Subitle_6: "'s staff members.",
    FormNewCompany_Step_3_Subitle_7: 'You can send more invitations later on too.',
    FormNewCompany_Step_3_Staffs_1: 'Send invitations to',
    FormNewCompany_Step_3_Staffs_2: 'Write one email and press ENTER',
    FormNewCompany_Done_Title: 'Congratulations!',
    FormNewCompany_Done_Subtitle: 'You can now visit your new company page',
    FormNewCompany_Done_1: 'Processing your information',
    FormNewCompany_Done_2: 'please wait...',
    FormNewCompany_Button_Create_Company: 'View company',
    FormNewCompany_Telephone_1: 'Phone number',
    // COMPANY COMPONENT
    Company_Articles_Title: 'Articles',
    Company_Services_Title: 'Services',
    Company_Rentals_Title: 'Rentals',
    Company_Tenders_Title: 'Tenders',
    Company_Fail_Loading_1: 'We are sorry but it seems like',
    Company_Fail_Loading_2: 'is not registed as a company',
    Company_Loading_1: 'Loading',
    Company_Loading_2: 'please wait...',
    Company_Owned_By: 'owned by',
    Company_Translations: 'Translations',
    Company_Join_Fivezer: 'Join Fivezer Network',
    Company_Edit: 'Edit company',
    Company_Manage_Members: 'Manage members',
    Company_Supplychains: 'View supplychains',
    Company_Create_Wallet: 'Create wallet',
    Company_View_Wallets: 'View wallets',
    Company_Copied: 'Copied!',
    Company_Copy_Pluging: 'Copy pluging',
    Company_Connect: 'Connect with',
    Company_Disconnect: 'Disconnect with',
    Company_Follow: 'Follow',
    Company_Unfollow: 'Unfollow',
    Company_Add_Favorite: 'Add as favorite',
    Company_Your_Feedback: 'Your feedback about',
    Company_Give_Feedback: 'Give your feedback',
    Company_View_Members: 'View members',
    Company_Send_Message: 'Send message',
    Company_New_Deal: 'New Deal',
    Company_Share_1: 'You can find more about',
    Company_Share_2: 'on ',
    Company_Registration: 'Registration',
    Company_Not_Verified: 'Not verified',
    Company_Verified: 'Verified',
    Company_No_Description: 'No description',
    Company_Visit: 'Visit',
    Company_Store: "'s store",
    Company_Locations_Title: 'Locations',
    Company_Partners_Title: 'Our partners',
    Company_Feedbacks_Title: 'Feedbacks',
    Company_No_Feedbacks: 'No customer feedbacks yet',
    Company_Receommended_Tools_Title: 'Our recommendations',
    Company_Receommended_Tools_Content: 'We have selected for you these partners who could help you improve your business',
    Company_Verify_Email_1: 'Warning: email',
    Company_Verify_Email_2: 'has not been verified by owner',
    Company_Followers: 'Followers',
    Company_Follower: 'Follower',
    Company_Not_Followers: 'No followers',
    Company_Awards: 'Awards',
    Company_Award: 'Award',
    // COMPANYLISTITEMS COMPONENT
    CompanyListItems_Add_Articles: 'Add article batch',
    CompanyListItems_Add_Service: 'Add service',
    CompanyListItems_Add_Tender: 'Add Tender',
    CompanyListItems_Add_Rental: 'Add Rental',
    CompanyListItems_No_Articles: 'No articles yet',
    CompanyListItems_No_Services: 'No services yet',
    CompanyListItems_No_Tenders: ' No tenders yet',
    CompanyListItems_No_Rentals: 'No rentals yet',
    // HORIZONTALENTITYLISTING COMPONENT
    HorizontalEntityListing_Updated: 'updated',
    HorizontalEntityListing_Status: 'Status',
    HorizontalEntityListing_Candidates: 'Candidates',
    HorizontalEntityListing_See_Mission: 'See mission',
    HorizontalEntityListing_Proposals: 'Proposals',
    HorizontalEntityListing_See: 'See',
    // LISTSERVICESBOOKINGS COMPONENT
    ListServicesBookings_Services: 'Services',
    ListServicesBookings_Service: 'Service',
    ListServicesBookings_Booked: 'booked by your customers',
    // LISTLATESTSELLS COMPONENT
    ListLatestSells_Sells: 'sells',
    ListLatestSells_Sell: 'sell',
    ListLatestSells_Booked: 'of your articles',
    ListLatestSells_Button_See_More: 'See all sells',
    // INPUTDESCRIPTION COMPONENT
    InputDescriptionsFeature_Descriptions_Label: 'Available languages (africans & internationals)',
    InputDescriptionsFeature_Descriptions_Placeholder: 'Select a language',
    InputDescriptionsFeature_Add_Language: 'Add language',
    InputDescriptionsFeature_Selected_Description_Label_1: 'Current descriptions',
    InputDescriptionsFeature_Selected_Description_Label_2: 'and',
    InputDescriptionsFeature_Selected_Description_Label_3: 'descriptions',
    InputDescriptionsFeature_Selected_Description_Label_4: 'description',
    InputDescriptionsFeature_Selected_Description_Label_5: 'with empty content',
    InputDescriptionsFeature_Selected_Description_Placeholder: 'Select a description',
    InputDescriptionsFeature_Short_Descriptions_Placeholder: 'Input a short description',
    InputDescriptionsFeature_Selected_Description_Caption: '(empty content)',
    InputDescriptionsFeature_Selected_Description_Content: 'Description in',
    InputDescriptionsFeature_Selected_Short_Description_Content: 'Short description in',
    InputDescriptionsFeature_Selected_Description_Content_Warning: 'Dont forget to save your content after each updates',
    InputDescriptionsFeature_African_Languages: 'AFRICAN LANGUAGES',
    InputDescriptionsFeature_OTHER_LANGUAGES: 'INTERNATIONAL LANGUAGES',
    // UTILITIES
    Utilities_Any_Industry: 'Any industries',
    Utilities_Any_Country: 'Any countries',
    Utilities_Any_Type: 'Any types',

    Utilities_Advertising: 'Advertising',
    Utilities_Agriculture: 'Agriculture',
    Utilities_Bank_Industry: 'Bank industry',
    Utilities_Communications: 'Communications',
    Utilities_Construction: 'Construction',
    Utilities_Creative_Industries: 'Creative industries',
    Utilities_Education: 'Education',
    Utilities_Electronics_Industry: 'Electronics industry',
    Utilities_Energy_Industry: 'Energy Industry',
    Utilities_Entertainment: 'Entertainment',
    Utilities_Farming: 'Farming',
    Utilities_Fashion: 'Fashion',
    Utilities_Finance: 'Finance',
    Utilities_Food_Industry: 'Food',
    Utilities_Football_Five_Industry: 'Football five industry',
    Utilities_Green: 'Green',
    Utilities_Health: 'Health',
    Utilities_Heavy: 'Heavy',
    Utilities_Hospitality_Industry: 'Hospitality industry',
    Utilities_Human_Resources_Industry: 'Human resources industry',
    Utilities_Information_Industry: 'Information',
    Utilities_Information_Technology: 'Information technology',
    Utilities_Infrastructure: 'Infrastructure',
    Utilities_IT_Industry: 'IT industry',
    Utilities_Logistics_Industry: 'Logistics industry',
    Utilities_Light_Industry: 'Light industry',
    Utilities_Manufacturing: 'Manufacturing',
    Utilities_Materials: 'Materials',
    Utilities_Music_Industry: 'Music',
    Utilities_News_Medias: 'News medias',
    Utilities_Pharmaceutical_Industry: 'Pharmaceutical industry',
    Utilities_Primary: 'Primary',
    Utilities_Public_Industry: 'Public industry',
    Utilities_Publishing_Industry: 'Publishing',
    Utilities_Retail: 'Retail',
    Utilities_Robotics: 'Robotics',
    Utilities_Secondary: 'Secondary',
    Utilities_Security_Service: 'Security service',
    Utilities_Services: 'Services',
    Utilities_Space: 'Space',
    Utilities_Sport: 'Sport',
    Utilities_Technology: 'Technology',
    Utilities_Telecom: 'Telecom',
    Utilities_Tertiary_Sector: 'Tertiary sector',
    Utilities_Textiles_Industry: 'Textiles industry',
    Utilities_Tourism: 'Tourism',
    Utilities_Transportation_Industry: 'Transportation',
    Utilities_Travel: 'Travel',
    Utilities_Female: 'Female',
    Utilities_Male: 'Male',
    Utilities_Other: 'Other',
    Utilities_XS_Parcel: 'XS Parcel',
    Utilities_S_Parcel: 'S Parcel',
    Utilities_M_Parcel: 'M Parcel',
    Utilities_L_Parcel: 'L Parcel',
    Utilities_XL_Parcel: 'XL Parcel',
    Utilities_XLL_Parcel: 'XLL Parcel',
    Utilities_Passport: 'Passport',
    Utilities_National_ID: 'National ID',
    Utilities_Driving_Licence: 'Driving Licence',
    Utilities_Temporarily_ID: 'Temporarily ID',
    Utilities_Permanent_Full_Time: 'Permanent (full-time)',
    Utilities_Permanent_Part_Time: 'Permanent (part-time)',
    Utilities_Temporary_Full_Time: 'Temporary (full-time)',
    Utilities_Temporary_Part_Time: 'Temporary (part-time)',
    Utilities_Internship_Full_Time: 'Internship (full-time)',
    Utilities_Internship_Part_Time: 'Internship (part-time)',
    Utilities_Create_As_Admin: 'Create admin',
    Utilities_View_As_Admin: 'View as admin',
    Utilities_Update_As_Admin: 'Update as admin',
    Utilities_Delete_As_Admin: 'Delete as admin',
    Utilities_Create_Positions: 'Create positions',
    Utilities_View_Own_Positions: 'View own positions',
    Utilities_View_Any_Positions: 'View any positions',
    Utilities_Update_Own_Positions: 'Update own positions',
    Utilities_Update_Any_Positions: 'Update any positions',
    Utilities_Delete_Own_Positions: 'Delete own positions',
    Utilities_Delete_Any_Positions: 'Delete any positions',
    Utilities_Create_Products: 'Create products',
    Utilities_View_Own_Products: 'View own products',
    Utilities_View_Any_Products: 'View any products',
    Utilities_Update_Own_Products: 'Update own products',
    Utilities_Update_Any_Products: 'Update any products',
    Utilities_Delete_Own_Products: 'Delete own products',
    Utilities_Delete_Any_Products: 'Delete any products',
    Utilities_Create_Services: 'Create services',
    Utilities_View_Own_Services: 'View own services',
    Utilities_View_Any_Services: 'View any services',
    Utilities_Update_Own_Services: 'Update own services',
    Utilities_Update_Any_Services: 'Update any services',
    Utilities_Delete_Own_Services: 'Delete own services',
    Utilities_Delete_Any_Services: 'Delete any services',
    Utilities_Create_Tenders: 'Create tenders',
    Utilities_View_Own_Tenders: 'View own tenders',
    Utilities_View_Any_Tenders: 'View any tenders',
    Utilities_Update_Own_Tenders: 'Update own tenders',
    Utilities_Update_Any_Tenders: 'Update any tenders',
    Utilities_Delete_Own_Tenders: 'Delete own tenders',
    Utilities_Delete_Any_Tenders: 'Delete any tenders',
    Utilities_Create_Job_Proposals: 'Create job proposals',
    Utilities_View_Own_Job_Proposals: 'View own job proposals',
    Utilities_View_Any_Job_Proposals: 'View any job proposals',
    Utilities_Update_Own_Job_Proposals: 'Update own job proposals',
    Utilities_Update_Any_Job_Proposals: 'Update any job proposals',
    Utilities_Delete_Own_Job_Proposals: 'Delete own job proposals',
    Utilities_Delete_Any_Job_Proposals: 'Delete any job proposals',
    Utilities_Create_Alerts: 'Create alerts',
    Utilities_View_Own_Alerts: 'View own alerts',
    Utilities_View_Any_Alerts: 'View any alerts',
    Utilities_Update_Own_Alerts: 'Update own alerts',
    Utilities_Update_Any_Alerts: 'Update any alerts',
    Utilities_Delete_Own_Alerts: 'Delete own alerts',
    Utilities_Delete_Any_Alerts: 'Delete any alerts',
    Utilities_Create_Quotations: 'Create quotations',
    Utilities_View_Own_Quotations: 'View own quotations',
    Utilities_View_Any_Quotations: 'View any quotations',
    Utilities_Update_Own_Quotations: 'Update own quotations',
    Utilities_Update_Any_Quotations: 'Update any quotations',
    Utilities_Delete_Own_Quotations: 'Delete own quotations',
    Utilities_Delete_Any_Quotations: 'Delete any quotations',
    Utilities_Aerospace_Company: 'Aerospace Company',
    Utilities_Agriculture_Company: 'Agriculture Company',
    Utilities_Auditor_Partnership: 'Auditor Partnership',
    Utilities_Automotive_Company: 'Automotive Company',
    Utilities_Biotechnology_Company: 'Biotechnology Company',
    Utilities_Cargo_Freight_Company: 'Cargo & Freight Company',
    Utilities_Cause: 'Cause',
    Utilities_Chemical_Company: 'Chemical Company',
    Utilities_College_University: 'College & University',
    Utilities_Community_Organization: 'Community Organization',
    Utilities_Community_Services: 'Community Services',
    Utilities_Computer_Company: 'Computer Company',
    Utilities_Consulting_Agency: 'Consulting Agency',
    Utilities_Elementary_School: 'Elementary School',
    Utilities_Energy_Company: 'Energy Company',
    Utilities_Finance_Company: 'Finance Company',
    Utilities_Fintech_Partnership: 'Fintech Partnership',
    Utilities_Fivezer_Partnership: 'Fivezer Partnership',
    Utilities_Food_Beverage_Company: 'Food & Beverage Company',
    Utilities_Football_League: 'Football league',
    Utilities_Government_Organization: 'Government Organization',
    Utilities_Health_Beauty_Company: 'Health/Beauty Company',
    Utilities_Health_Partnership: 'Health Partnership',
    Utilities_High_School: 'High School',
    Utilities_Industrial_Company: 'Industrial Company',
    Utilities_Insurance_Company: 'Insurance Company',
    Utilities_Internet_Company: 'Internet Company',
    Utilities_Labor_Union: 'Labor Union',
    Utilities_Legal_Company: 'Legal Company',
    Utilities_Logistics: 'Logistics',
    Utilities_Logistics_Partnership: 'Logistics Partnership',
    Utilities_Insurances_Partnership: 'Insurances Partnership',
    Utilities_Media_News_Company: 'Media/News Company',
    Utilities_Medical_Company: 'Medical Company',
    Utilities_Middle_School: 'Middle School',
    Utilities_Mining_Company: 'Mining Company',
    Utilities_Non_Governmental_Organization: 'Non-Governmental Organization',
    Utilities_Non_Profit_Organization: 'Non-Profit Organization',
    Utilities_Organization: 'Organization',
    Utilities_Political_Organization: 'Political Organization',
    Utilities_Political_Party: 'Political Party',
    Utilities_Preschool: 'Preschool',
    Utilities_Religious_Organization: 'Religious Organization',
    Utilities_Retail_Company: 'Retail Company',
    Utilities_Retail_Investor_Partnership: 'Retail Investor Partnership',
    Utilities_Risk_Management_Partnership: 'Risks Management Partnership',
    Utilities_School: 'School',
    Utilities_Science: 'Science',
    Utilities_Security_Partnership: 'Security Partnership',
    Utilities_Statistics: 'Statistics',
    Utilities_Talent_Resources_Partnership: 'Talent Resources Partnership',
    Utilities_Commodity_Compliance_Partnership: 'Commodity Compliance Partnership',
    Utilities_Technology_Engineering: 'Technology & Engineering',
    Utilities_Telecommunication_Company: 'Telecommunication Company',
    Utilities_Tobacco_Company: 'Tobacco Company',
    Utilities_Travel_Company: 'Travel Company',
    Utilities_Skin_cares: 'Skin cares',
    Utilities_Agriculture_Food: 'Agriculture & Food',
    Utilities_Apparel: 'Apparel',
    Utilities_Appliances: 'Appliances',
    Utilities_Apps_Games: 'Apps & Games',
    Utilities_Arts_Crafts_Sewing: 'Arts, Crafts, & Sewing',
    Utilities_Automotive_Parts_Accessories: 'Automotive Parts & Accessories',
    Utilities_Baby: 'Baby',
    Utilities_Beauty_Personal_Care: 'Beauty & Personal Care',
    Utilities_Books: 'Books',
    Utilities_CDs_Vinyl: 'CDs & Vinyl',
    Utilities_Cell_Phones_Accessories: 'Cell Phones & Accessories',
    Utilities_Clothing_Shoes_and_Jewelry: 'Clothing, Shoes and Jewelry',
    Utilities_Collectibles_Fine_Art: 'Collectibles & Fine Art',
    Utilities_Computers: 'Computers',
    Utilities_Electronics: 'Electronics',
    Utilities_Fashion_Accessories: 'Fashion Accessories',
    Utilities_Food_Beverage: 'Food & Beverage',
    Utilities_Furniture: 'Furniture',
    Utilities_Gifts_Crafts: 'Gifts & Crafts',
    Utilities_Garden_Outdoor: 'Garden & Outdoor',
    Utilities_Health_Household_Baby_Care: 'Health, Household & Baby Care',
    Utilities_Home_Appliances: 'Home Appliances',
    Utilities_Home_Kitchen: 'Home & Kitchen',
    Utilities_Home_Garden: 'Home & Garden',
    Utilities_Industrial_Scientific: 'Industrial & Scientific',
    Utilities_Lights_Lighting: 'Lights & Lighting',
    Utilities_Luggage_Travel_Gear: 'Luggage & Travel Gear',
    Utilities_Machinery: 'Machinery',
    Utilities_Movies_TV: 'Movies & TV',
    Utilities_Musical_Instruments: 'Musical Instruments',
    Utilities_Office_Products: 'Office Products',
    Utilities_Packaging_Printing: 'Packaging & Printing',
    Utilities_Pet_Supplies: 'Pet Supplies',
    Utilities_Shoes_Accessories: 'Shoes & Accessories',
    Utilities_Sports_Entertainment: 'Sports & Entertainment',
    Utilities_Sports_Outdoors: 'Sports & Outdoors',
    Utilities_Textiles_Leather_Products: 'Textiles & Leather Products',
    Utilities_Timepieces_Jewelry_Eyewear: 'Timepieces, Jewelry, Eyewear',
    Utilities_Tools_Home_Improvement: 'Tools & Home Improvement',
    Utilities_Toys_Hobbies: 'Toys & Hobbies',
    Utilities_Toys_Games: 'Toys & Games',
    Utilities_Video_Games: 'Video Games',
    Utilities_Company: 'Company',
    Utilities_Service: 'Service',
    Utilities_Product: 'Product',
    Utilities_Accounting: 'Accounting',
    Utilities_Administrative: 'Administrative',
    Utilities_Assistance_Advertising: 'Assistance Advertising',
    Utilities_Android_Development: 'Android Development',
    Utilities_Animation_Architecture: 'Animation Architecture',
    Utilities_Audiology: 'Audiology',
    Utilities_Audio_Engineering: 'Audio Engineering',
    Utilities_Auto_Insurance: 'Auto Insurance',
    Utilities_Bankruptcy_Law: 'Bankruptcy Law',
    Utilities_Bartending: 'Bartending',
    Utilities_Blogging: 'Blogging',
    Utilities_Bookkeeping: 'Bookkeeping',
    Utilities_Budgeting: 'Budgeting',
    Utilities_Business_Analytics: 'Business Analytics',
    Utilities_Business_Law: 'Business Law',
    Utilities_Catering_Service: 'Catering Service',
    Utilities_Coaching_Mentoring: 'Coaching & Mentoring',
    Utilities_Commercial_Insurance: 'Commercial Insurance',
    Utilities_Commercial_Real_Estate: 'Commercial Real Estate',
    Utilities_Computer_Networking: 'Computer Networking',
    Utilities_Computer_Repair: 'Computer Repair',
    Utilities_Consulting: 'Consulting',
    Utilities_Content_Strategy: 'Content Strategy',
    Utilities_Copywriting: 'Copywriting',
    Utilities_Corporate_Events: 'Corporate Events',
    Utilities_Corporate_Law: 'Corporate Law',
    Utilities_Customer_Service: 'Customer Service',
    Utilities_Customer_Support: 'Customer Support',
    Utilities_Cybersecurity: 'Cybersecurity',
    Utilities_Digital_Marketing: 'Digital Marketing',
    Utilities_DJing: 'DJing',
    Utilities_DUI_Law: 'DUI Law',
    Utilities_Editing: 'Editing',
    Utilities_Email_Marketing: 'Email Marketing',
    Utilities_Event_Photography: 'Event Photography',
    Utilities_Event_Planning: 'Event Planning',
    Utilities_Executive_Coaching: 'Executive Coaching',
    Utilities_Family_Law: 'Family Law',
    Utilities_File_Management: 'File Management',
    Utilities_Filing: 'Filing',
    Utilities_Financial_Accounting: 'Financial Accounting',
    Utilities_Financial_Advisory: 'Financial Advisory',
    Utilities_Financial_Analysis: 'Financial Analysis',
    Utilities_Financial_Planning: 'Financial Planning',
    Utilities_Financial_Reporting: 'Financial Reporting',
    Utilities_Ghostwriting: 'Ghostwriting',
    Utilities_Grant_Writing: 'Grant Writing',
    Utilities_Graphic_Design: 'Graphic Design',
    Utilities_Growth_Marketing: 'Growth Marketing',
    Utilities_Headshot_Photography: 'Headshot Photography',
    Utilities_Health_Insurance: 'Health Insurance',
    Utilities_Healthcare_Consulting: 'Healthcare Consulting',
    Utilities_Home_Networking: 'Home Networking',
    Utilities_Home_Owners_Insurance: 'Home Owners Insurance',
    Utilities_HR_Consulting: 'HR Consulting',
    Utilities_Human_Resources: 'Human Resources',
    Utilities_Illustration: 'Illustration',
    Utilities_Immigration_Law: 'Immigration Law',
    Utilities_Information_Management: 'Information Management',
    Utilities_Information_Security: 'Information Security',
    Utilities_Insurance: 'Insurance',
    Utilities_Interaction_Design: 'Interaction Design',
    Utilities_Interior_Design: 'Interior Design',
    Utilities_iOS_Development: 'iOS Development',
    Utilities_Labor_and_Employment_Law: 'Labor and Employment Law',
    Utilities_Lead_Generation: 'Lead Generation',
    Utilities_Leadership_Development: 'Leadership Development',
    Utilities_Life_Coaching: 'Life Coaching',
    Utilities_Life_Insurance: 'Life Insurance',
    Utilities_Loans: 'Loans',
    Utilities_Logo_Design: 'Logo Design',
    Utilities_Management_Consulting: 'Management Consulting',
    Utilities_Manufacturing_Service: 'Manufacturing services',
    Utilities_Market_Research: 'Market Research',
    Utilities_Market_Strategy: 'Market Strategy',
    Utilities_Marketing: 'Marketing',
    Utilities_Mobile_Marketing: 'Mobile Marketing',
    Utilities_Mortgage_Lending: 'Mortgage Lending',
    Utilities_Nature_Photography: 'Nature Photography',
    Utilities_Negotiation: 'Negotiation',
    Utilities_Network_Support: 'Network Support',
    Utilities_Nonprofit_Consulting: 'Nonprofit Consulting',
    Utilities_Notary_Online_Research: 'NotaryOnline Research',
    Utilities_Outsourcing: 'Outsourcing',
    Utilities_Packaging_Design: 'Packaging Design',
    Utilities_Personal_Injury_Law: 'Personal Injury Law',
    Utilities_Portrait_Photography: 'Portrait Photography',
    Utilities_Pricing_Strategy: 'Pricing Strategy',
    Utilities_Print_Design: 'Print Design',
    Utilities_Product_Marketing: 'Product Marketing',
    Utilities_Program_Management: 'Program Management',
    Utilities_Project_Management: 'Project Management',
    Utilities_Property_Management: 'Property Management',
    Utilities_Public_Relations: 'Public Relations',
    Utilities_Public_Speaking: 'Public Speaking',
    Utilities_Real_Estate: 'Real Estate',
    Utilities_Real_Estate_Appraisal: 'Real Estate Appraisal',
    Utilities_Relocation: 'Relocation',
    Utilities_Research: 'Research',
    Utilities_Resume_Writing: 'Resume Writing',
    Utilities_Retirement_Planning: 'Retirement Planning',
    Utilities_Search_Engine_Marketing: 'Search Engine Marketing (SEM)',
    Utilities_Search_Engine_Optimization: 'Search Engine Optimization (SEO)',
    Utilities_Social_Media_Marketing: 'Social Media Marketing',
    Utilities_Software_Testing: 'Software Testing',
    Utilities_Strategic_Planning: 'Strategic Planning',
    Utilities_Tax_Law: 'Tax Law',
    Utilities_Tax_Preparation: 'Tax Preparation',
    Utilities_Team_Building: 'Team Building',
    Utilities_Technical: 'Technical',
    Utilities_Support_Technical_Writing: 'Support Technical Writing',
    Utilities_Telecommunications: 'Telecommunications',
    Utilities_Trade_Shows: 'Trade Shows',
    Utilities_Training: 'Training',
    Utilities_Translation: 'Translation',
    Utilities_Typing: 'Typing',
    Utilities_User_Experience_Design: 'User Experience Design',
    Utilities_User_Experience_Writing: 'User Experience Writing',
    Utilities_UX_Research: 'UX Research',
    Utilities_Video_Animation: 'Video Animation',
    Utilities_Video_Editing: 'Video Editing',
    Utilities_Video_Production: 'Video Production',
    Utilities_Videography: 'Videography',
    Utilities_Virtual_Assistance: 'Virtual Assistance',
    Utilities_Visual_Design: 'Visual Design',
    Utilities_Wealth_Management: 'Wealth Management',
    Utilities_Web_Design: 'Web Design',
    Utilities_Web_Development: 'Web Development',
    Utilities_Wedding_Photography: 'Wedding Photography',
    Utilities_Wedding_Planning: 'Wedding Planning',
    Utilities_WordPress_Design: 'WordPress Design',
    Utilities_Writing: 'Writing',

    Utilities_Affirmation: 'Affirmation',
    Utilities_Affirmation_1: "I'm satisfied with my purchase!",
    Utilities_Affirmation_2: 'Great experience overall.',
    Utilities_Affirmation_3: 'Thank you for your excellent service!',

    Utilities_Anger: 'Anger',
    Utilities_Anger_1: "I'm frustrated with the service.",
    Utilities_Anger_2: 'Disappointed with my experience.',
    Utilities_Anger_3: 'Unhappy with the product quality.',

    Utilities_Anxiety: 'Anxiety',
    Utilities_Anxiety_1: 'Feeling anxious about the purchase.',
    Utilities_Anxiety_2: 'Concerned about the service.',
    Utilities_Anxiety_3: 'Anxiety due to a bad experience.',

    Utilities_Blushing: 'Blushing',
    Utilities_Blushing_1: 'Feeling embarrassed about the purchase.',
    Utilities_Blushing_2: 'Blushing due to an awkward situation.',
    Utilities_Blushing_3: 'Embarrassed about the service received.',

    Utilities_Contempt: 'Contempt',
    Utilities_Contempt_1: 'Feeling contemptuous about the purchase.',
    Utilities_Contempt_2: 'Displeased with the service.',
    Utilities_Contempt_3: 'Contempt towards the product quality.',

    Utilities_Despair: 'Despair',
    Utilities_Despair_1: 'Feeling hopeless about the purchase.',
    Utilities_Despair_2: 'Despair due to a bad experience.',
    Utilities_Despair_3: 'Hopeless about the service received.',

    Utilities_Determination: 'Determination',
    Utilities_Determination_1: 'Determined to resolve an issue.',
    Utilities_Determination_2: 'Motivated to overcome challenges.',
    Utilities_Determination_3: 'Firm resolve to improve the service.',

    Utilities_Devotion: 'Devotion',
    Utilities_Devotion_1: 'Devoted to the brand and its products.',
    Utilities_Devotion_2: 'Loyal and committed to continued support.',
    Utilities_Devotion_3: 'Dedicated to a positive customer experience.',

    Utilities_Disdain: 'Disdain',
    Utilities_Disdain_1: 'Feeling disdainful about the purchase.',
    Utilities_Disdain_2: 'Disdain towards the service provided.',
    Utilities_Disdain_3: 'Displeased with the overall experience.',

    Utilities_Disgust: 'Disgust',
    Utilities_Disgust_1: 'Feeling disgusted with the purchase.',
    Utilities_Disgust_2: 'Disgust towards the service received.',
    Utilities_Disgust_3: 'Unpleasantly surprised by the product quality.',

    Utilities_Fear: 'Fear',
    Utilities_Fear_1: 'Fearful about the purchase outcome.',
    Utilities_Fear_2: 'Anxious about the service quality.',
    Utilities_Fear_3: 'Fear of facing issues with the product.',

    Utilities_Grief: 'Grief',
    Utilities_Grief_1: 'Feeling grief about the purchase.',
    Utilities_Grief_2: 'Saddened by a disappointing experience.',
    Utilities_Grief_3: 'Grieving the poor service received.',

    Utilities_Guilt: 'Guilt',
    Utilities_Guilt_1: 'Feeling guilty about the purchase decision.',
    Utilities_Guilt_2: 'Regretful about the service chosen.',
    Utilities_Guilt_3: 'Guilt about the negative impact on experience.',

    Utilities_Hatred: 'Hatred',
    Utilities_Hatred_1: 'Feeling intense hatred about the purchase.',
    Utilities_Hatred_2: 'Hatred towards the service provider.',
    Utilities_Hatred_3: 'Strong aversion to the overall experience.',

    Utilities_Helplessness: 'Helplessness',
    Utilities_Helplessness_1: 'Feeling helpless about the purchase situation.',
    Utilities_Helplessness_2: 'Helpless in dealing with the service issues.',
    Utilities_Helplessness_3: 'Overwhelmed by a sense of helplessness.',

    Utilities_HighSpirits: 'High Spirits',
    Utilities_HighSpirits_1: 'In high spirits due to the positive purchase.',
    Utilities_HighSpirits_2: 'Energetic and enthusiastic about the service.',
    Utilities_HighSpirits_3: 'Joyful and excited about the overall experience.',

    Utilities_IllTemper: 'Ill-temper',
    Utilities_IllTemper_1: 'Feeling ill-tempered due to the purchase.',
    Utilities_IllTemper_2: 'Irritated by the service provided.',
    Utilities_IllTemper_3: 'In a bad mood due to the overall experience.',

    Utilities_Joy: 'Joy',
    Utilities_Joy_1: 'Experiencing joy and happiness with the purchase.',
    Utilities_Joy_2: 'Joyful and satisfied with the service.',
    Utilities_Joy_3: 'Happy and delighted with the overall experience.',

    Utilities_Love: 'Love',
    Utilities_Love_1: 'Feeling love and appreciation for the purchase.',
    Utilities_Love_2: 'Love and admiration for the service received.',
    Utilities_Love_3: 'Deep affection and fondness for the overall experience.',

    Utilities_LowSpirits: 'Low Spirits',
    Utilities_LowSpirits_1: 'Feeling downhearted about the purchase.',
    Utilities_LowSpirits_2: 'Discouraged by the service quality.',
    Utilities_LowSpirits_3: 'Low spirits due to the overall experience.',

    Utilities_Mediation: 'Mediation',
    Utilities_Mediation_1: 'Open to mediation for resolving issues.',
    Utilities_Mediation_2: 'Willingness to discuss concerns through mediation.',
    Utilities_Mediation_3: 'Seeking a mediated solution for service problems.',

    Utilities_Modesty: 'Modesty',
    Utilities_Modesty_1: 'Modest satisfaction with the purchase.',
    Utilities_Modesty_2: 'Humble acknowledgment of the service.',

    Utilities_Modesty_3: 'Appreciating the experience with modesty.',

    Utilities_Negation: 'Negation',
    Utilities_Negation_1: 'Negating satisfaction with the purchase.',
    Utilities_Negation_2: 'Disagreeing with the service quality.',
    Utilities_Negation_3: 'Expressing negation about the overall experience.',

    Utilities_Neutral: 'Neutral',
    Utilities_Neutral_1: 'Feeling neutral about the purchase.',
    Utilities_Neutral_2: 'Having a neutral stance on the service.',
    Utilities_Neutral_3: 'No strong emotion towards the overall experience.',

    Utilities_Patience: 'Patience',
    Utilities_Patience_1: 'Exercising patience in dealing with issues.',
    Utilities_Patience_2: 'Patiently waiting for service resolution.',
    Utilities_Patience_3: 'Maintaining patience throughout the overall experience.',

    Utilities_Pride: 'Pride',
    Utilities_Pride_1: 'Proud of the purchase decision.',
    Utilities_Pride_2: 'Taking pride in the service received.',
    Utilities_Pride_3: 'Feeling a sense of pride about the overall experience.',

    Utilities_Reflection: 'Reflection',
    Utilities_Reflection_1: 'Reflecting on the purchase choices.',
    Utilities_Reflection_2: 'Engaging in self-reflection after the service.',
    Utilities_Reflection_3: 'Thoughtful reflection on the overall experience.',

    Utilities_SelfAttention: 'Self-attention',
    Utilities_SelfAttention_1: 'Giving self-attention to feedback and concerns.',
    Utilities_SelfAttention_2: 'Focusing on self-improvement after the service.',
    Utilities_SelfAttention_3: 'Valuing self-attention in the overall experience.',

    Utilities_Shyness: 'Shyness',
    Utilities_Shyness_1: 'Feeling shy about expressing feedback.',
    Utilities_Shyness_2: 'Shy but appreciative of the service.',
    Utilities_Shyness_3: 'Expressing feedback with a hint of shyness.',

    Utilities_Suffering: 'Suffering',
    Utilities_Suffering_1: 'Suffering due to the purchase experience.',
    Utilities_Suffering_2: 'Experiencing distress from the service received.',
    Utilities_Suffering_3: 'Enduring suffering throughout the overall experience.',

    Utilities_Sulkiness: 'Sulkiness',
    Utilities_Sulkiness_1: 'Feeling sulkiness about the purchase outcome.',
    Utilities_Sulkiness_2: 'Sulking due to dissatisfaction with the service.',
    Utilities_Sulkiness_3: 'Expressing sulkiness about the overall experience.',

    Utilities_Surprise: 'Surprise',
    Utilities_Surprise_1: 'Surprised by the unexpected aspects of the purchase.',
    Utilities_Surprise_2: 'Positive surprise with the service.',
    Utilities_Surprise_3: 'Expressing surprise about the overall positive experience.',

    Utilities_TenderFeelings: 'Tender Feelings',
    Utilities_TenderFeelings_1: 'Embracing tender feelings towards the purchase.',
    Utilities_TenderFeelings_2: 'Expressing delicate emotions about the service.',
    Utilities_TenderFeelings_3: 'Cherishing tender feelings in the overall experience.',

    Utilities_Weeping: 'Weeping',
    Utilities_Weeping_1: 'Feeling like weeping due to the purchase experience.',
    Utilities_Weeping_2: 'Tears shed over a disappointing service.',
    Utilities_Weeping_3: 'Expressing sorrow through weeping in the overall experience.',

    Utilities_Alert_Awareness: 'Awareness',
    Utilities_Alert_Warning: 'Warning',
    Utilities_Alert_Danger: 'Danger',

    Utilities_Chemical_Industry: 'Chemical Industry',
    Utilities_Metals_Industry: 'Metals Industry',
    Utilities_Textile_Industry: 'Textile Industry',
    Utilities_Plastics_Industry: 'Plastics Industry',
    Utilities_Paper_Pulp_Industry: 'Paper and Pulp Industry',
    Utilities_Ceramics_Industry: 'Ceramics Industry',
    Utilities_Glass_Industry: 'Glass Industry',
    Utilities_Rubber_Industry: 'Rubber Industry',
    Utilities_Wood_Timber_Industry: 'Wood Industry',
    Utilities_Aerospace_Industry: 'Aerospace Industry',
    Utilities_Defense_Industry: 'Defense Industry',
    Utilities_Mining_Industry: 'Mining Industry',
    Utilities_Water_Treatment_Industry: 'Water Treatment Industry',
    Utilities_Renewable_Energy_Industry: 'Renewable Energy Industry',
    Utilities_Chemical_Process_Equipment: 'Chemical Process Equipment',
    Utilities_Packaging_Industry: 'Packaging Industry',
    Utilities_Pharmaceutical_Manufacturing: 'Pharmaceutical Manufacturing',
    Utilities_HVAC_Industry: 'Heating, Ventilation, and Air Conditioning (HVAC) Industry',
    Utilities_Instrumentation_Control_Systems: 'Instrumentation and Control Systems',
    Utilities_Robotics_Automation_Industry: 'Robotics and Automation Industry',
    Utilities_Waste_Management: 'Waste Management',

    Utilities_Bearings: 'Bearings',
    Utilities_Conveyor_Systems: 'Conveyor Systems',
    Utilities_Electrical_Components: 'Electrical Components',
    Utilities_Fasteners: 'Fasteners',
    Utilities_Filtration_Systems: 'Filtration Systems',
    Utilities_Hydraulic_Equipment: 'Hydraulic Equipment',
    Utilities_Industrial_Coatings: 'Industrial Coatings',
    Utilities_Instrumentation: 'Instrumentation',
    Utilities_Lubricants: 'Lubricants',
    Utilities_Machining_Tools: 'Machining Tools',
    Utilities_Motors: 'Motors',
    Utilities_Pneumatic_Equipment: 'Pneumatic Equipment',
    Utilities_Power_Tools: 'Power Tools',
    Utilities_Pumps: 'Pumps',
    Utilities_Raw_Materials: 'Raw Materials',
    Utilities_Safety_Equipment: 'Safety Equipment',
    Utilities_Sensors: 'Sensors',
    Utilities_Valves: 'Valves',
    Utilities_Welding_Equipment: 'Welding Equipment',

    Utilities_Publicity: 'Publicity',
    Utilities_Recruiting: 'Recruiting',
    Utilities_Relationship_Coaching: 'Relationship Coaching',
    Utilities_Residential_Real_Estate: 'Residential Real Estate',
    Utilities_Sales: 'Sales',
    Utilities_Sales_Training: 'Sales Training',
    Utilities_Scriptwriting: 'Scriptwriting',
    Utilities_SEO: 'SEO',
    Utilities_Social_Media_Strategy: 'Social Media Strategy',
    Utilities_Software_Development: 'Software Development',
    Utilities_Technical_Support: 'Technical Support',
    Utilities_Technical_Writing: 'Technical Writing',
    Utilities_Community_Outreach: 'Community Outreach',
    Utilities_Environmental_Consulting: 'Environmental Consulting',
    Utilities_Legal_Consulting: 'Legal Consulting',
    Utilities_Risk_Management: 'Risk Management',
    Utilities_Sustainability_Consulting: 'Sustainability Consulting',
    Utilities_Industrial_Automation: 'Industrial Automation',
    Utilities_Manufacturing_Consulting: 'Manufacturing Consulting',
    Utilities_Process_Engineering: 'Process Engineering',
    Utilities_Quality_Control: 'Quality Control',
    Utilities_Safety_Engineering: 'Safety Engineering',
    Utilities_Supply_Chain_Management: 'Supply Chain Management',
    Utilities_Industrial_Equipment_Maintenance: 'Industrial Equipment Maintenance',
    Utilities_Facility_Management: 'Facility Management',
    Utilities_Energy_Management: 'Energy Management',
    Utilities_Environmental_Compliance: 'Environmental Compliance',
    Utilities_Industrial_Design: 'Industrial Design',
    Utilities_Logistics_Management: 'Logistics Management',
    Utilities_Materials_Management: 'Materials Management',
    Utilities_Plant_Engineering: 'Plant Engineering',
    Utilities_Project_Engineering: 'Project Engineering',
    Utilities_Industrial_Training: 'Industrial Training',
    Utilities_Maintenance_Planning: 'Maintenance Planning',
    Utilities_Adhesives_Sealants: 'Adhesives and Sealants',
    Utilities_Software: 'Software',
    Utilities_Home_Decor: 'Home Decor',
    Utilities_Stationery: 'Stationery',
    Utilities_Craft_Beverages: 'Craft Beverages',
    Utilities_Outdoor_Recreation: 'Outdoor Recreation',
    Utilities_Watches: 'Watches',
    Utilities_Baby_Clothing: 'Baby Clothing',
    Utilities_Personal_Care_Appliances: 'Personal Care Appliances',

    // SETTINGS COMPONENT
    Settings_Title: 'Settings',
    Settings_Account: 'Account',
    Settings_Wallets: 'Wallets',
    Settings_Languages: 'Languages',
    Settings_Helpdesk: 'Helpdesk',
    Settings_Legal_Mentions: 'Legal mentions',
    Settings_Logout: 'Logout',
    Settings_Logging_Out: 'Logging out...',
    // NEWDEAL COMPONENT
    NewDeal_Processing: 'Processing deal, please wait...',
    // NEWCOMMODITYCOMPLIANCE MODAL
    NewRequestCommodityComplianceModal_Title: 'New compliance case for',
    NewRequestCommodityComplianceModal_Button_New_Case: 'New compliance case',
    NewRequestCommodityComplianceModal_Descriptions_Title: 'Descriptions (multi-languages)',
    NewRequestCommodityComplianceModal_Button_Create: 'Submit request',
    // VIEWCOMMODITYCOMPLIANCEREQUEST MODAL
    ViewCommodityComplianceRequestsModal_Button_View: 'View requests',
    // EditCommodityComplianceProposalModal
    EditCommodityComplianceProposalModal_Title: 'Update proposal',
    EditCommodityComplianceProposalModal_Descriptions_Title: 'Descriptions (multi-languages)',
    EditCommodityComplianceProposalModal_Tasks_Title: 'Tasks listing',
    EditCommodityComplianceProposalModal_Duration: 'Duration',
    EditCommodityComplianceProposalModal_Duration_Unit: 'Duration unit',
    EditCommodityComplianceProposalModal_Total_Cost_Title: 'Total cost',
    EditCommodityComplianceProposalModal_Currency: 'Currency',
    EditCommodityComplianceProposalModal_Payment_Plan_Title: 'Payment plan',
    // EditRequestCommodityComplianceModal
    EditRequestCommodityComplianceModal_Title: 'Update commodity request',
    EditRequestCommodityComplianceModal_Descriptions_Title: 'Descriptions (multi-languages)',
    EditRequestCommodityComplianceModal_Button_Update: 'Save updates',
    // NewCommodityComplianceProposalModal
    NewCommodityComplianceProposalModal_Title: 'New proposal',
    NewCommodityComplianceProposalModal_Descriptions_Title: 'Descriptions (multi-languages)',
    NewCommodityComplianceProposalModal_Tasks_Title: 'Tasks listing',
    NewCommodityComplianceProposalModal_Duration: 'Duration',
    NewCommodityComplianceProposalModal_Duration_Unit: 'Duration unit',
    NewCommodityComplianceProposalModal_Total_Cost_Title: 'Total cost',
    NewCommodityComplianceProposalModal_Currency: 'Currency',
    NewCommodityComplianceProposalModal_Payment_Plan_Title: 'Payment plan',
    NewCommodityComplianceProposalModal_Button_Create: 'Submit proposal',
    // NEWCANDIDATEREQUEST MODAL
    FormNewCandidateRequestModal_Title: 'New candidate request',
    FormNewCandidateRequestModal_Descriptions_Title: 'Descriptions (multi-languages)',
    FormNewCandidateRequestModal_Total_Cost_Title: 'Cost',
    FormNewCandidateRequestModal_Currency: 'Currency',
    FormNewCandidateRequestModal_Button_Create: 'Submit request',
    // EDITCANDIDATEREQUEST MODAL
    FormEditCandidateRequestModal_Title: 'Edit candidate request',
    FormEditCandidateRequestModal_Descriptions_Title: 'Descriptions (multi-languages)',
    FormEditCandidateRequestModal_Total_Cost_Title: 'Cost',
    FormEditCandidateRequestModal_Currency: 'Currency',
    // NEWCANDIDATEPROPOSAL MODAL
    FormNewCandidateProposalModal_Title: 'New candidate proposal',
    FormNewCandidateProposalModal_Descriptions_Title: 'Descriptions (multi-languages)',
    FormNewCandidateProposalModal_Candidate: 'Candidate',
    FormNewCandidateProposalModal_Button_Create: 'Submit proposal',
    // EDITCANDIDATEPROPOSAL MODAL
    FormEditCandidateProposalModal_Title: 'Edit candidate proposal',
    FormEditCandidateProposalModal_Descriptions_Title: 'Descriptions (multi-languages)',

    // DISCOVERING PAGE
    Discovering_Meta_Title: 'E-commerce in Africa now simple, reliable and worldwide',
    Discovering_Title: 'E-commerce in Africa now simple, reliable and worldwide',
    Discovering_About: 'Learn more about Lilenek.com',
    Discovering_Most_Search_1: 'Frequently searched',
    Discovering_Most_Search_2: 'Agro business machines',
    Discovering_Most_Search_3: 'Raw materials for cosmetics',
    Discovering_Most_Search_4: 'Quality auditor',
    Discovering_Benefits_1: 'Vast Selection of Business Solutions',
    Discovering_Benefits_2: 'Explore a diverse range of products, services and suppliers tailored to your business needs. With millions of offerings available across African and global markets, our platform connects you with the right solutions to expand your business effortlessly.',
    Discovering_Benefits_3: 'Guaranteed Secure Transactions',
    Discovering_Benefits_4: 'Work with verified suppliers to ensure top-quality production while enjoying full transaction protection. From payment to delivery, our platform guarantees the security of your orders, giving you peace of mind when trading locally and internationally.',
    Discovering_Benefits_5: 'Complete turnkey business solution',
    Discovering_Benefits_6: 'Simplify your trade process with an all-in-one solution. From finding the right products and suppliers to managing orders, payments, and fulfillment, our platform streamlines every step to support seamless cross-border and intra-African commerce.',
    Discovering_Benefits_7: 'Customized Trading Experience',
    Discovering_Benefits_8: 'Access tailored benefits such as exclusive discounts, enhanced protection, and dedicated support, designed to accelerate your business growth across African and global markets. This personalized approach ensures you thrive at every stage of the trading journey.',
    Discovering_Exploring_1: 'Discover millions of offerings tailored to your business needs',
    Discovering_Exploring_2: 'items',
    Discovering_Exploring_3: 'suppliers',
    Discovering_Exploring_4: 'items categories',
    Discovering_Exploring_5: 'countries and regions',
    Discovering_Opportunity_1: 'Uncover your next business opportunity',
    Discovering_Opportunity_2: 'by',
    Discovering_Sourcing_1: 'Source directly from manufacturers',
    Discovering_Sourcing_2: 'Get samples',
    Discovering_Sourcing_3: 'Take factory live tours',
    Discovering_Sourcing_4: 'Connect with top-ranking manufacturers',
    Discovering_Sourcing_5: 'Filter suppliers by your criterias',
    Discovering_Sourcing_6: 'View more',
    Discovering_Purchase_1: 'Trade with confidence from production quality to purchase protection',
    Discovering_Purchase_2: 'Ensure production quality with',
    Discovering_Purchase_3: 'suppliers',
    Discovering_Purchase_4: 'Collaborate with a diverse range of suppliers who have third-party-verified credentials and proven capabilities. Look for the',
    Discovering_Purchase_5: 'Verified',
    Discovering_Purchase_6: 'logo to source products from experienced suppliers you can trust for your business needs.',
    Discovering_Purchase_7: 'Watch video',
    Discovering_Purchase_8: 'Pay your purchases with',
    Discovering_Purchase_9: 'Trade with confidence on Lilenek, where secure payment options, comprehensive protection against product and shipping issues, and mediation support are provided for any purchase-related concerns. Enjoy peace of mind with every order and payment.',
    Discovering_Streamline_1: 'Streamline your purchases from search to fulfillment, all in one place',
    Discovering_Streamline_2: 'Search for matches',
    Discovering_Streamline_3: 'Easily search and filter through millions of product, service and supplier offerings to identify the best matches for your business needs. Our platform helps you pinpoint the right solutions quickly and efficiently.',
    Discovering_Streamline_4: 'Identify the right fit',
    Discovering_Streamline_5: 'Assess product quality and supplier capabilities effortlessly with verified inspections and advanced digital sourcing tools. Our platform streamlines the evaluation process to help you make informed decisions quickly and efficiently.',
    Discovering_Streamline_6: 'Pay with confidence',
    Discovering_Streamline_7: 'Complete your transactions securely in over 50 currencies using more than 15 trusted payment methods, with options for flexible payment terms. Enjoy a seamless and protected payment experience for all your orders.',
    Discovering_Streamline_8: 'Delivery with transparency',
    Discovering_Streamline_9: 'Meet your logistics needs with tailored solutions from Menelinks (Lilenek logistics services). Benefit from real-time tracking across 26,000+ routes in 220 countries and regions, all powered by Lilenek for complete visibility and control.',
    Discovering_Streamline_10: 'Manage with ease',
    Discovering_Streamline_11: 'Effortlessly monitor order status, manage suppliers, track payments and shipments, and access after-sales support—all through Lilenek’s integrated in-app support system.',
    Discovering_Discounted_1: 'Discover Customized Discounts, Services, and Tools for All Business Sizes',
    Discovering_Discounted_2: 'Discover curated benefits on Lilenek, from essential resources for small businesses just starting out to advanced tools and solutions for established enterprises managing complex orders. Enjoy tailored support to help your business grow at every stage, all available for free.',
    Discovering_Discounted_3: 'CEO of Mensah Organic Farms',
    Discovering_Discounted_4: 'As an entrepreneur in the Agro-business industry, I have been passionate about cultivating high-quality organic produce. Lilenek has been a key partner in bringing my vision to life.',
    Discovering_Discounted_5: 'Founder of Pure Skin Naturals',
    Discovering_Discounted_6: 'Being deeply committed to crafting eco-friendly cosmetics, I needed a reliable partner. Lilenek has been instrumental in helping me deliver clean and effective products to my customers.',
    Discovering_Discounted_7: 'Co-founder of ExploreIt Travel',
    Discovering_Discounted_8: 'In the tourism industry, creating memorable experiences is essential. Lilenek has supported us every step of the way in building unique travel packages for our clients.',
    Discovering_Discounted_9: 'Founder of Okoye Builders',
    Discovering_Discounted_10: 'As a business owner in the construction industry, I’ve always been focused on building strong, reliable structures. Lilenek has been my go-to partner, helping us bring every project to completion with precision and quality.',
    Discovering_Discounted_11: 'Discover them',
    Discovering_Get_Started_1: 'Ready to get started?',
    Discovering_Get_Started_2: 'Explore millions of opportunities from trusted suppliers by signing up today!',
    Discovering_Empowering_1: 'Empowering businesses through intra-african & global trade',
    Discovering_Empowering_2: 'Lilenek offers one-stop B2B2C trading solutions for global small and medium-sized businesses, empowering them to transform through digital trade, grasp opportunities, and accelerate growth internationally.',
    Discovering_Empowering_3: 'Our mission',
    Discovering_Empowering_4: 'Make it easy to do business from anywhere, across the African market.',
    Discovering_Empowering_5: 'Our locations',
    Discovering_Empowering_6: 'We have teams around the world.',
    Discovering_Empowering_7: 'Our ESG promises',
    Discovering_Empowering_8: 'Responsible technology, sustainable future & application of circular economies',
    Discovering_Find_Suppliers_1: 'Find suppliers by country or region',
    Discovering_Search_0: 'Search',
    Discovering_Search_1: 'Solar panels',
    Discovering_Search_2: 'Fertilizers bio',
    Discovering_Search_3: 'Agricultural production machine',
    Discovering_Search_4: 'Conciergery service',
    Discovering_Search_5: 'Pure Shea Butter',
    Discovering_Search_6: 'Daycare service',
    Discovering_Testimonials_1: 'They tell you about their experience',

    NewSponsorshipModal_Descriptions_Title: 'Descriptions',
    NewSponsorshipModal_Price_Title: 'Sponsorship price',
    NewSponsorshipModal_Price_Value_1: 'Price',
    NewSponsorshipModal_Price_Currency: 'Currency',
    NewSponsorshipModal_Button_Create: 'Submit sponsorship',

    NewSponsorshipProposalModal_Descriptions_Title: 'Descriptions',
    NewSponsorshipProposalModal_Price_Title: 'Proposal price',
    NewSponsorshipProposalModal_Price_Value_1: 'Price',
    NewSponsorshipProposalModal_Price_Currency: 'Currency',
    NewSponsorshipProposalModal_Button_Create: 'Submit proposal',

    ViewSponsorshipProposalModal_Proposed_Sponsorship: 'Proposed sponsorship',
    ViewSponsorshipProposalModal_Payment_Plan_1: 'You will receive',
    ViewSponsorshipProposalModal_Payment_Plan_2: 'where the first will be on the',
    ViewSponsorshipProposalModal_Payment_Plan_3: 'and the last will be on the',
    ViewSponsorshipProposalModal_Button_Accept: 'Accept',
    ViewSponsorshipProposalModal_Button_Reject: 'Reject',
    ViewSponsorshipProposalModal_Response_1: 'Do you really want to accept this proposal?',
    ViewSponsorshipProposalModal_Response_2: 'Do you really want to reject this proposal?',

    AddSponsorshipNoteModal_Title: 'Add final note',
    AddSponsorshipNoteModal_Button_Create: 'Submit note',

    WishWesh_Title: 'Make a wish, we might grant it',
    WishWesh_Comment_Placeholder: 'want...',
    WishWesh_Loading: 'Transmitting your wish...',
    WishWesh_No_More_Wishes: 'You made all your daily wishes, try again tomorrow',

    Categories_Nb_Categories_1: 'Categories',
    Categories_Nb_Categories_2: 'Category',
    Categories_Nb_Countries_1: 'Countries',
    Categories_Nb_Countries_2: 'Country',
  },
};

{
  /* <FormattedMessage id="" />&nbsp; */
}
