import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material';
import { selectUser } from '../../reducers/userSlice';
import { useAppSelector } from '../../redux/hooks';

interface ILoadingComponent {
  text?: any;
  loader?: any;
  dark_mode?: boolean;
  amount?: number;
}

const LoadingComponent: React.FC<ILoadingComponent> = (props: ILoadingComponent) => {
  const loggedUser = useAppSelector(selectUser);
  const bgcolor = (props.dark_mode !== undefined ? props.dark_mode : loggedUser?.app_configuration?.is_dark_mode) ? 'rgba(247, 251, 250, .06)' : 'rgba(15, 15, 15, .1)';
  const animation = 'pulse';
  const borderRadius = '10px';

  return (
    <React.Fragment>
      <Grid id="max-width" sx={{ width: '100%', m: '30px 0' }}>
        <Grid sx={{ textAlign: 'center' }}>
          {props?.loader}
          <Grid sx={{ opacity: '.4' }}>{props?.text}</Grid>
        </Grid>
        {_.times(props?.amount ?? 0)?.map((t: any, index: number) => (
          <Card key={index} sx={{ bgcolor: 'transparent', boxShadow: 'none', m: '20px 10%' }}>
            <CardHeader avatar={<Skeleton animation={animation} variant="circular" width={40} height={40} style={{ backgroundColor: bgcolor }} />} title={<Skeleton animation={animation} height={10} width="80%" style={{ backgroundColor: bgcolor }} />} subheader={<Skeleton animation={animation} height={10} width="40%" style={{ backgroundColor: bgcolor }} />} />
            <Skeleton sx={{ height: 190 }} animation={animation} variant="rectangular" style={{ backgroundColor: bgcolor, borderRadius: borderRadius }} />
            <CardContent>
              <>
                <Skeleton animation={animation} height={10} style={{ backgroundColor: bgcolor }} />
                <Skeleton animation={animation} height={10} width="80%" style={{ backgroundColor: bgcolor }} />
              </>
            </CardContent>
          </Card>
        ))}
      </Grid>
    </React.Fragment>
  );
};

LoadingComponent.propTypes = {
  text: PropTypes.any,
  loader: PropTypes.any,
  dark_mode: PropTypes.bool,
  amount: PropTypes.number,
};

LoadingComponent.defaultProps = {
  text: '',
  loader: false,
};

export default React.memo(LoadingComponent);
