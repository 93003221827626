import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { RootState } from '../redux/store';

export interface CartsState {
  carts: any;
}

const initialState: CartsState = {
  carts: [],
};

export const cartSlice = createSlice({
  name: 'carts',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    storeCarts: (state, action: PayloadAction<any>) => {
      state.carts = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.carts = [];
    });
  },
});

export const { storeCarts } = cartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCarts = (state: RootState) => state.user_carts.carts;

export default cartSlice.reducer;
