import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { RootState } from '../redux/store';

export interface CompaniesState {
  companies: any;
}

const initialState: CompaniesState = {
  companies: [],
};

export const companySlice = createSlice({
  name: 'companies',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    storeCompanies: (state, action: PayloadAction<any>) => {
      state.companies = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.companies = [];
    });
  },
});

export const { storeCompanies } = companySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCompanies = (state: RootState) => state.owner.companies;

export default companySlice.reducer;
