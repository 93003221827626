import React from 'react';
import './sass/index.css';
import App from './App';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, QueryOptions, concat, split } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { selectSession } from './reducers/sessionSlice';
import { useAppSelector } from './redux/hooks';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import ErrorBoundary from './ErrorBoundary';
import { REACT_APP_GRAPHQL_URI, REACT_APP_GRAPHQL_WEBSOCKET_URI } from './config';

const Constructor: React.FC = () => {
  const PROTOCOL = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
  const uri = `${PROTOCOL}${REACT_APP_GRAPHQL_URI}/api`;
  const cache = new InMemoryCache();
  const session = useAppSelector(selectSession);

  const httpLink = new HttpLink({
    uri: uri,
    fetch: (uri, options: any) => {
      const timeoutFromHeader = options?.headers?.['x-timeout'];
      const timeout = timeoutFromHeader || 180000;
      return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
          reject(new Error('Request timed out.'));
        }, timeout);
        fetch(uri, options).then(
          (response) => {
            clearTimeout(timer);
            resolve(response);
          },
          (err) => {
            clearTimeout(timer);
            reject(err);
          }
        );
      });
    },
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: REACT_APP_GRAPHQL_WEBSOCKET_URI,
    })
  );
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (session?.token?.key) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'Authorization': session?.token?.key ? `Bearer ${session?.token?.key}` : null,
          'X-Anonymous-Access': 'false',
        },
      }));

      return forward(operation);
    } else {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'X-Anonymous-Access': 'true',
        },
      }));

      return forward(operation);
    }
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    cache,
    link: concat(authMiddleware, link),
    connectToDevTools: process.env.NODE_ENV === 'development' ? true : false,
    assumeImmutableResults: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
        errorPolicy: 'all',
        // You can set a timeout for watchQuery as well
        timeout: 180000 as number, // 3 minutes
      } as unknown as Partial<QueryOptions<any, any>>,
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        // You can set a timeout for query as well
        timeout: 180000 as number, // 3 minutes
      } as Partial<QueryOptions<any, any>>,
      mutate: {
        errorPolicy: 'all',
      },
    },
  });

  return (
    <>
      <ApolloProvider client={client}>
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </LocalizationProvider>
        </>
      </ApolloProvider>
    </>
  );
};

export default React.memo(Constructor);
