import React, { useState, useEffect } from 'react';

const ErrorBoundary = ({ children }: any) => {
  const [hasError, setHasError] = useState(false);

  const onReset = () => {
    // Reload the page immediately
    window.location.reload();
  };

  useEffect(() => {
    const handleError = (event: any) => {
      const { message } = event;
      console.log('Error caught by error boundary:', message);

      // Check if the error message contains the specific HTTP2 error
      if (message && message?.includes('net::ERR_FAILED 401')) {
        setHasError(true);
        onReset();
      }
    };

    // Attach the error event listener
    window.addEventListener('error', handleError);

    return () => {
      // Clean up the event listener
      window.removeEventListener('error', handleError);
    };
  }, []);

  // Listen to console events
  window.addEventListener('message', (event) => {
    if (event.source === window && event.data && event.data.source === 'console-api') {
      const logMessage = event.data.message;
      if (logMessage.includes('net::ERR_FAILED 401')) {
        // Log or handle the specific error message here
        console.error('Captured 401 error:', logMessage);
        // Reload the page immediately
        window.location.reload();
      }
    }
  });

  if (hasError) {
    return (
      <div>
        <p>HTTP2 protocol error detected. Reloading the page...</p>
      </div>
    );
  }

  return children;
};

export default React.memo(ErrorBoundary);
