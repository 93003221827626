import { FC, memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { selectUser, storeUser, selectUserActivePosition, storeUserActivePosition } from '../../reducers/userSlice';
import { storeCompanies, selectCompanies } from '../../reducers/companySlice';
import { storePersonalWallets, storeBusinessWallets, storeAssociatedPaymentMethods, selectAssociatedPaymentMethods } from '../../reducers/walletSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';

const SessionController: FC = () => {
  try {
    const FIND_USER_BY_ID_QUERY = gql`
      query findUserByID($id: ID!) {
        findUserByID(id: $id) {
          id
          username
          mobile
          bio
          roles
          permissions
          status
          email {
            address
            status
          }
          kyc_cases {
            id
            reference
            status
            created_at
            audited_at
            incorporation_file {
              id
              filename
              path
            }
            user_front_id {
              id
              filename
              path
            }
            user_back_id {
              id
              filename
              path
            }
            user_id_selfie {
              id
              filename
              path
            }
            user_residential_proof {
              id
              filename
              path
            }
            pid {
              id_number
              type
              status
            }
          }
          avatar {
            id
            filename
            type
            encoding
            size
            path
          }
          cover {
            id
            filename
            type
            encoding
            size
            path
          }
          social_medias {
            id
            name
            handle
            link
          }
          app_configuration {
            id
            is_dark_mode
            two_facts_mode
            prefered_language
          }
          followings {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
          }
          followers {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
          owner {
            id
            firstname
            lastname
            gender
            date_of_birth
            emails {
              address
              status
            }
            companies {
              id
              name
              label
              logo {
                path
              }
            }
            player_account {
              id
              status
              username
              avatar {
                path
              }
              profiles {
                ... on Footballer {
                  __typename
                  id
                  alias
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                }
              }
              social_medias {
                id
                name
                handle
                link
              }
            }
            complex_owner_account {
              id
              status
              username
              avatar {
                path
              }
              five_complexes {
                id
              }
              social_medias {
                id
                name
                handle
                link
              }
            }
            transporter_account {
              id
              status
              username
              avatar {
                path
              }
            }
            health_worker_account {
              id
              status
              username
              avatar {
                path
              }
            }
          }
          positions {
            id
            status
            permissions
            role
            company {
              id
              name
              label
              rate
              created_at
              logo {
                path
              }
              subcategories {
                name
              }
              positions {
                employees {
                  id
                  username
                }
              }
              shipping_price_list {
                id
                status
              }
              partnerships {
                id
                name
                status
                company {
                  id
                  name
                }
              }
              wallets {
                id
                status
                title
                payment_methods {
                  id
                  name
                }
              }
            }
          }
          locations {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          rentals {
            id
          }
        }
      }
    `;

    const FIND_USER_COMPANIES_QUERY = gql`
      query findUserCompanies($owner_id: ID!) {
        findUserCompanies(owner_id: $owner_id) {
          id
          name
          label
          rate
          email {
            status
            address
          }
          logo {
            path
          }
          subcategories {
            name
          }
          social_medias {
            id
            name
            handle
            link
          }
          shipping_price_list {
            id
            status
          }
          wallets {
            id
            title
            status
            type
            owner {
              ... on Company {
                __typename
                id
                name
              }
              ... on User {
                __typename
                id
                username
              }
            }
            payment_methods {
              id
              status
              is_default
            }
          }
          kyc_cases {
            id
            status
          }
          received_notifications {
            id
            status
          }
          connections {
            id
            name
            label
            logo {
              path
            }
          }
          positions {
            id
            employees {
              id
              username
            }
          }
          locations {
            id
            status
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          created_at
          updated_at
        }
      }
    `;

    const FIND_USER_PERSONAL_WALLETS_QUERY = gql`
      query findUserPersonalWallets($user: inputUserWallets!) {
        findUserPersonalWallets(user: $user) {
          id
          title
          status
          is_default
          type
          owner {
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
          mobile_money_accounts {
            id
          }
          bank_accounts {
            id
          }
          blockchain_accounts {
            id
            wallet_id
            wallet_addresses
            type
            title
            is_default
            payment_methods {
              id
            }
          }
          payment_methods {
            id
            name
            status
            payment_providers {
              id
              name
              status
              available_currencies
            }
            payment_source {
              ... on BankCard {
                __typename
                id
                network
                accepted_currencies
              }
              ... on BlockchainCard {
                __typename
                id
                network
                accepted_currencies
              }
              ... on BlockchainAccount {
                __typename
                id
                title
                accepted_currencies
              }
              ... on BankAccount {
                __typename
                id
                title
                accepted_currencies
              }
              ... on Cash {
                __typename
                id
                alternative_name: name
                status
              }
              ... on Gold {
                __typename
                id
                alternative_name: name
                status
              }
              ... on Waste {
                __typename
                id
                alternative_name: name
                status
              }
              ... on MobileMoney {
                __typename
                id
                name
                status
                accepted_currencies
              }
            }
            cashout_account {
              ... on BankCard {
                __typename
                id
                network
                accepted_currencies
              }
              ... on BankAccount {
                __typename
                id
                title
                accepted_currencies
              }
              ... on MobileMoney {
                __typename
                id
                name
                status
                mobile_number
                accepted_currencies
              }
            }
          }
          sent_transactions {
            id
          }
        }
      }
    `;

    const FIND_USER_COMPANIES_WALLETS_QUERY = gql`
      query findUserCompaniesWallets($user: inputCompaniesWallets!) {
        findUserCompaniesWallets(user: $user) {
          id
          name
          label
          wallets {
            id
            title
            status
            is_default
            type
            owner {
              ... on Company {
                __typename
                id
                name
                label
                logo {
                  path
                  filename
                }
              }
              ... on User {
                __typename
                id
                username
                avatar {
                  path
                  filename
                }
              }
            }
            mobile_money_accounts {
              id
            }
            bank_accounts {
              id
            }
            blockchain_accounts {
              id
              wallet_id
              wallet_addresses
              type
              title
              is_default
              payment_methods {
                id
              }
            }
            beneficiaries {
              id
              firstname
              lastname
            }
            cards {
              ... on BankCard {
                __typename
                id
                network
                status
              }
              ... on BlockchainCard {
                __typename
                id
                network
                status
              }
            }
            payment_methods {
              id
              name
              status
              payment_providers {
                id
                name
                status
                available_currencies
              }
              payment_source {
                ... on BankCard {
                  __typename
                  id
                  network
                  accepted_currencies
                }
                ... on BlockchainCard {
                  __typename
                  id
                  network
                  accepted_currencies
                }
                ... on BlockchainAccount {
                  __typename
                  id
                  title
                  accepted_currencies
                }
                ... on BankAccount {
                  __typename
                  id
                  title
                  accepted_currencies
                }
                ... on Cash {
                  __typename
                  id
                  alternative_name: name
                  status
                }
                ... on Gold {
                  __typename
                  id
                  alternative_name: name
                  status
                }
                ... on Waste {
                  __typename
                  id
                  alternative_name: name
                  status
                }
                ... on MobileMoney {
                  __typename
                  id
                  name
                  status
                  accepted_currencies
                }
              }
              cashout_account {
                ... on BankCard {
                  __typename
                  id
                  network
                  accepted_currencies
                }
                ... on BankAccount {
                  __typename
                  id
                  title
                  accepted_currencies
                }
                ... on MobileMoney {
                  __typename
                  id
                  name
                  status
                  mobile_number
                  accepted_currencies
                }
              }
            }
            sent_transactions {
              id
            }
          }
        }
      }
    `;

    const FIND_USER_ASSOCIATED_PAYMENT_METHODS_QUERY = gql`
      query findUserAssociatedPaymentMethods($user: inputUserWallets!) {
        findUserAssociatedPaymentMethods(user: $user) {
          id
          name
          label
          status
        }
      }
    `;

    const SELECT_PREFERED_LANGUAGE_MUTATION = gql`
      mutation selectPreferedLanguage($account: inputSelectPreferedLangue!) {
        selectPreferedLanguage(account: $account)
      }
    `;

    const session = useAppSelector(selectSession);
    const dispatch = useAppDispatch();
    const loggedUser = useAppSelector(selectUser);
    const loggedUserActivePosition = useAppSelector(selectUserActivePosition);
    const loggedUserCompanies = useAppSelector(selectCompanies);
    const loggedUserAssociatedPaymentMethods = useAppSelector(selectAssociatedPaymentMethods);
    const [offLineUser, setOffLineUser] = useState(UNDEFINED_AS_ANY);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [selectPreferedLanguage, updatedLanguage] = useMutation(SELECT_PREFERED_LANGUAGE_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const loadedUser = useQuery(FIND_USER_BY_ID_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key || !loggedUser?.id,
      variables: { id: loggedUser?.id },
      pollInterval: session.status !== 'ALIVE' || !loggedUser?.id ? undefined : 300000,
    });
    const loadedCompanies = useQuery(FIND_USER_COMPANIES_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key || !loggedUser?.id,
      variables: { owner_id: loggedUser?.id },
      pollInterval: session.status !== 'ALIVE' || !loggedUser?.id ? undefined : 300000,
    });
    const loadedUserPersonalWallets = useQuery(FIND_USER_PERSONAL_WALLETS_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key || !loggedUser?.id,
      variables: {
        user: { id: loggedUser?.id, username: loggedUser?.username },
      },
    });
    const loadedUserCompaniesWallets = useQuery(FIND_USER_COMPANIES_WALLETS_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key || !loggedUser?.id || !loggedUserCompanies,
      variables: {
        user: {
          companies_id: loggedUserCompanies?.map((c: any) => c.id),
          username: loggedUser?.username,
          id: loggedUser?.id,
        },
      },
    });
    const loadedUserAssociatedPaymentMethods = useQuery(FIND_USER_ASSOCIATED_PAYMENT_METHODS_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key || !loggedUser?.id,
      variables: {
        user: { id: loggedUser?.id, username: loggedUser?.username },
      },
    });

    useEffect(() => {
      // Get the user's browser language
      const userLang = window.navigator.language || 'en';

      // Set the lang attribute of the HTML tag
      document.documentElement.lang = userLang;
    }, []);

    useEffect(() => {
      if (session.status !== 'ALIVE' && pathname !== '/' && pathname !== '/login' && pathname !== '/signup' && pathname !== '/forgot_password' && pathname !== '/join_complex_owner_network' && pathname !== '/legal_mentions' && !pathname?.includes('/ls/') && !pathname?.includes('/livestreams') && !pathname?.includes('/badge/')) {
        navigate('/login');
      }

      if (!_.isMatch(loggedUser, loadedUser?.data?.findUserByID) || (loadedUser?.called && loadedUser?.data?.findUserByID !== undefined)) {
        try {
          dispatch(storeUser(loadedUser?.data?.findUserByID));
        } catch (e: any) {
          console.log(e);
        }
      }

      if (!loggedUserActivePosition?.id && loggedUser?.positions?.filter((p: any) => p?.status === 'FILLED')?.length > 0) {
        try {
          dispatch(storeUserActivePosition(loggedUser?.positions?.filter((p: any) => p?.status === 'FILLED')[0]));
        } catch (e: any) {
          console.log(e);
        }
      }

      if (loggedUserActivePosition && loggedUserActivePosition?.status === 'VACANT') {
        try {
          dispatch(storeUserActivePosition(undefined));
        } catch (e: any) {
          console.log(e);
        }
      }

      if (!_.isMatch(loggedUserCompanies, loadedCompanies?.data?.findUserCompanies) || (loadedCompanies?.data?.findUserCompanies !== undefined && loadedCompanies?.called)) {
        try {
          dispatch(storeCompanies(loadedCompanies?.data?.findUserCompanies));
        } catch (e: any) {
          console.log(e);
        }
      }
    }, [session, navigate, pathname, loggedUser, loadedUser, dispatch, loggedUserCompanies, loadedCompanies, loggedUserActivePosition]);

    useEffect(() => {
      if (loadedUserPersonalWallets.called && loadedUserPersonalWallets.data?.findUserPersonalWallets) {
        try {
          dispatch(storePersonalWallets(loadedUserPersonalWallets.data?.findUserPersonalWallets));
        } catch (e: any) {
          console.log(e);
        }
      }
    }, [loadedUserPersonalWallets, dispatch]);

    useEffect(() => {
      if (loadedUserCompaniesWallets.called && loadedUserCompaniesWallets.data?.findUserCompaniesWallets && loadedUserCompaniesWallets.data?.findUserCompaniesWallets?.length > 0) {
        try {
          dispatch(
            storeBusinessWallets(
              loadedUserCompaniesWallets.data?.findUserCompaniesWallets
                .map((c: any) => {
                  return c.wallets;
                })
                .flat()
            )
          );
        } catch (e: any) {
          console.log(e);
        }
      }
    }, [loadedUserCompaniesWallets, dispatch, loggedUserCompanies]);

    useEffect(() => {
      if ((loadedUserAssociatedPaymentMethods.called && loadedUserAssociatedPaymentMethods.data?.findUserAssociatedPaymentMethods) || !_.isEqual(loggedUserAssociatedPaymentMethods, loadedUserAssociatedPaymentMethods.data?.findUserAssociatedPaymentMethods)) {
        try {
          dispatch(storeAssociatedPaymentMethods(loadedUserAssociatedPaymentMethods.data?.findUserAssociatedPaymentMethods));
        } catch (e: any) {
          console.log(e);
        }
      }
    }, [loadedUserAssociatedPaymentMethods, dispatch, loggedUserAssociatedPaymentMethods]);

    useEffect(() => {
      (async () => {
        if (((!loggedUser?.id && !offLineUser) || !_.isMatch(loggedUser, offLineUser)) && loggedUser?.app_configuration?.prefered_language) {
          setOffLineUser(loggedUser);
        }

        if (!updatedLanguage.called && offLineUser?.app_configuration?.prefered_language && loggedUser?.id && !_.isMatch(offLineUser?.app_configuration?.prefered_language, loggedUser?.app_configuration?.prefered_language)) {
          try {
            await selectPreferedLanguage({
              variables: {
                account: {
                  id: loggedUser?.id,
                  new_language: offLineUser?.app_configuration?.prefered_language,
                },
              },
            });

            setOffLineUser({
              app_configuration: {
                prefered_language: offLineUser?.app_configuration?.prefered_language,
              },
            });
          } catch (e: any) {
            console.log(e);
          }
        }
      })();
    }, [offLineUser, loggedUser, updatedLanguage, selectPreferedLanguage]);

    return null;
  } catch (e: any) {
    console.log(e);
    return null;
  }
};

export default memo(SessionController);
