export const REACT_APP_API_LAYER_KEY = process.env.REACT_APP_API_LAYER_KEY || '';
export const REACT_APP_CUSTOM_MAP_URL = process.env.REACT_APP_CUSTOM_MAP_URL || '';
export const REACT_APP_MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY || '';
export const REACT_APP_GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI || '';
export const REACT_APP_ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY || '';
export const REACT_APP_CLIENT_NAME = process.env.REACT_APP_CLIENT_NAME || 'LILENEK_PRO';
export const REACT_APP_IP_INFO_TOKEN = process.env.REACT_APP_IP_INFO_TOKEN || '';
export const REACT_APP_GRAPHQL_WEBSOCKET_URI = process.env.REACT_APP_GRAPHQL_WEBSOCKET_URI || '';
export const REACT_APP_GRAPHQL_LIVESTREAM_WEBSOCKET_URI = process.env.REACT_APP_GRAPHQL_LIVESTREAM_WEBSOCKET_URI || '';
export const REACT_APP_ASSEMBLY_AI_API = process.env.REACT_APP_ASSEMBLY_AI_API || '';
export const REACT_APP_VAPID_PUBLIC_KEY = process.env.REACT_APP_VAPID_PUBLIC_KEY || '';
export const REACT_APP_VAPID_PRIVATE_KEY = process.env.REACT_APP_VAPID_PRIVATE_KEY || '';
export const REACT_APP_FIVEZER_URL = process.env.REACT_APP_FIVEZER_URL || '';
export const REACT_APP_HERE_API_ID = process.env.REACT_APP_HERE_API_ID || '';
export const REACT_APP_HERE_API_KEY = process.env.REACT_APP_HERE_API_KEY || '';
