import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { RootState } from '../redux/store';

export interface UserState {
  loggedUser: any;
  loggedUserActivePosition: any;
}

const initialState: UserState = {
  loggedUser: {},
  loggedUserActivePosition: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    storeUser: (state: any, action: PayloadAction<any>) => {
      // console.log("state.user", action.payload)
      state.loggedUser = action.payload;
    },
    storeUserActivePosition: (state: any, action: PayloadAction<any>) => {
      // console.log("state.user", action.payload)
      state.loggedUserActivePosition = action.payload;
    },
    switchUserLanguage: (state: any, action: PayloadAction<any>) => {
      // console.log("state.user", action.payload)
      state.loggedUser = action.payload;
    },
    removePreviousUser: (state: any) => {
      state.loggedUser = {
        app_configuration: {
          prefered_language: state.loggedUser?.app_configuration?.prefered_language,
        },
      };
    },
  },

  extraReducers: (builder: any) => {
    builder.addCase(PURGE, (state: any) => {
      state.loggedUser = {
        app_configuration: {
          prefered_language: state.loggedUser?.app_configuration?.prefered_language,
          previous_user: {
            username: state.loggedUser?.username,
            avatar: state.loggedUser?.avatar,
            email: state.loggedUser?.email?.address,
          },
        },
      };
      state.loggedUserActivePosition = {};
    });
  },
});

export const { storeUser, storeUserActivePosition, switchUserLanguage, removePreviousUser } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState): any => state.user.loggedUser;
export const selectUserActivePosition = (state: RootState): any => state.user.loggedUserActivePosition;

export default userSlice.reducer;
