import React, { Suspense } from 'react';
import LoadingComponent from './component-modals/common/LoadingComponent';
import { CircularProgress, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ILazyLoadingWrapper {
  children: React.ReactNode;
}

const LazyLoadingWrapper: React.FC<ILazyLoadingWrapper> = (props: ILazyLoadingWrapper) => {
  return (
    <Suspense
      fallback={
        <LoadingComponent
          amount={0}
          text={
            <Typography sx={{ color: 'rgba(255,255,255,.4)' }}>
              <FormattedMessage id="Global_Button_Loading_Wait" />
            </Typography>
          }
          loader={<CircularProgress className="green-loader" />}
        />
      }
    >
      {props.children}
    </Suspense>
  );
};

export default React.memo(LazyLoadingWrapper);
